import { MutableRefObject, useCallback, useLayoutEffect, useState } from 'react'

export const useIsOverflowed = (
  ref: MutableRefObject<null | HTMLDivElement>,
  { callback = (_checkOverflow: boolean) => {}, updateOnWindowResize = true } = {},
) => {
  const [isOverflowed, setIsOverflowed] = useState(false)

  const listener = useCallback(() => {
    const dispatch = () => {
      if (ref.current) {
        const checkOverflow = ref.current.scrollHeight > ref.current.clientHeight
        setIsOverflowed(checkOverflow)
        callback(checkOverflow)
      }
    }

    if (ref.current !== null) {
      dispatch()
    }
  }, [callback, ref])

  useLayoutEffect(() => {
    listener()
    if (updateOnWindowResize) {
      window.addEventListener('resize', listener) // re-assess overflow on resize
    }
    return () => {
      if (updateOnWindowResize) {
        window.removeEventListener('resize', listener)
      }
    }
  }, [callback, listener, ref, updateOnWindowResize])

  return isOverflowed
}
