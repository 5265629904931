import { Button, Icon } from '@navinc/base-react-components/wayfinder'

export type OfferPaginationControlsProps = {
  hasMoreItems: boolean
  hasLessItems: boolean
  showMoreItems: () => void
  showLessItems: () => void
}

export const OfferPaginationControls = ({
  hasMoreItems,
  hasLessItems,
  showMoreItems,
  showLessItems,
}: OfferPaginationControlsProps) => {
  return (
    <div className="pagination-controls body2 flex gap-200">
      {hasMoreItems && (
        <Button variant="primaryPlain" onClick={showMoreItems}>
          Show more <Icon name="keyboard_arrow_down" />
        </Button>
      )}
      {hasMoreItems && hasLessItems && <span>|</span>}
      {hasLessItems && (
        <Button variant="primaryPlain" onClick={showLessItems}>
          Show less <Icon name="keyboard_arrow_up" />
        </Button>
      )}
    </div>
  )
}
