import 'react-multi-carousel/lib/styles.css'

import { type FC, Fragment, type ReactNode } from 'react'
import InternalMultiCarousel, { CarouselProps as MultiCarouselProps } from 'react-multi-carousel'

// The typescript types are not importing correctly.  It might have something to do with commonjs
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- when using commonjs, it doesn't recognize `default` as a property
const MultiCarousel = ((InternalMultiCarousel as any)?.default ??
  InternalMultiCarousel) as unknown as FC<MultiCarouselProps>

export type CarouselProps = {
  slides: ReactNode[]
  overrides?: Partial<MultiCarouselProps>
}

export const Carousel = ({ slides = [], overrides }: CarouselProps) => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: slides.length < 4 ? slides.length : 4,
      partialVisibilityGutter: 40,
      slidesToSlide: slides.length < 4 ? slides.length : 4,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
  }

  const defaultCarouselProps = {
    additionalTransfrom: 0,
    arrows: false,
    autoPlaySpeed: 3000,
    centerMode: false,
    className: '',
    containerClass: 'container-with-dots ',
    dotListClass: '',
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: 'px-150',
    keyBoardControl: true,
    minimumTouchDrag: 80,
    pauseOnHover: true,
    renderArrowsWhenDisabled: false,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive,
    rewind: false,
    rewindWithAnimation: false,
    rtl: false,
    shouldResetAutoplay: true,
    showDots: false,
    sliderClass: '',
    slidesToSlide: 1,
    swipeable: true,
    autoPlay: true,
  }
  return (
    <div>
      <div className="CarouselWrapper relative justify-center">
        <MultiCarousel {...defaultCarouselProps} {...overrides}>
          {slides.map((slide, i) => (
            <Fragment key={i}>{slide}</Fragment>
          ))}
        </MultiCarousel>
      </div>
    </div>
  )
}
