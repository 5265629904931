import * as RadixAccordion from '@radix-ui/react-accordion'

import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { RichText } from '../../_components/RichText/index.js'
import { Children } from '../../_components/RichText/serialize.js'
import { cmsSectionFragment, SectionHeader } from '../../_components/SectionHeader/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import classes from './accordion.module.scss'

export const accordionFragment = graphql(
  /* GraphQL */ `
    fragment accordionFragment on CmsAccordion {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      accordionItems {
        title
        mainContent
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type AccordionProps = {
  data: FragmentOf<typeof accordionFragment>
  isNested?: boolean
}

export const Accordion = ({ data, isNested }: AccordionProps) => {
  const { accordionItems, section, ctas, invertBackground } = readFragment(accordionFragment, data)
  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="m-auto" data-testid="accordion:accordionItems">
          <RadixAccordion.Root
            className={`${classes.AccordionRoot} mx-auto my-0 flex w-full max-w-[946px] flex-col items-start rounded-200 border border-outlineVariant bg-surfaceBright`}
            type="single"
            collapsible
          >
            {accordionItems?.map((accordion, i) => {
              return (
                <RadixAccordion.Item className={classes.AccordionItem} key={i} value={`item-${i}`}>
                  <RadixAccordion.Header
                    className="mx-0 flex items-center gap-[10px] self-stretch p-150"
                    data-testid={`accordion:accordionItems:${i}`}
                    asChild
                  >
                    <RadixAccordion.Trigger className={classes.AccordionTrigger}>
                      {accordion.title}
                      <div className={classes.AccordionChevron}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                          <rect width="15" height="15" fill="white" fillOpacity="0.01" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                            fill="currentcolor"
                          />
                        </svg>
                      </div>
                    </RadixAccordion.Trigger>
                  </RadixAccordion.Header>
                  <RadixAccordion.Content className={classes.AccordionContent}>
                    <div className="w-full px-150 pt-150">
                      <RichText content={accordion.mainContent as Children} />
                      <div className="flex w-full p-100" />
                    </div>
                  </RadixAccordion.Content>
                </RadixAccordion.Item>
              )
            })}
          </RadixAccordion.Root>
        </div>
      </Gutter>
    </BlockSection>
  )
}
