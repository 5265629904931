import { InferComponentProps } from '@navinc/base-react-components'

import { OverlayGradient } from '@/app/_components/OverlayGradient/index.jsx'
import { VideoFragmentType } from '@/app/_graphql/videos.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { FullBleedHero } from '../shared/FullBleedHero.jsx'
import { HeroContent } from '../shared/HeroContent.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'

export type VideoHeroProps = {
  links: InferComponentProps<typeof HeroContent>['links']
  richText: InferComponentProps<typeof HeroContent>['richText']
  mobileVideo?: VideoFragmentType | null
  mobileFallback?: Parameters<typeof getMediaSizeUrlWithFallback>[0]
  desktopVideo?: VideoFragmentType | null
  desktopFallback?: Parameters<typeof getMediaSizeUrlWithFallback>[0]
  richTextMax?: InferComponentProps<typeof HeroContent>['maxWidth']
  className?: string
  heroSize?: InferComponentProps<typeof FullBleedHero>['heroSize'] | null
  disclaimerText?: InferComponentProps<typeof HeroContent>['disclaimerText']
  horizontalAlign?: InferComponentProps<typeof HeroContent>['horizontalAlign']
  verticalAlign?: 'top' | 'bottom' | 'center' | null
}

export const VideoHero = ({
  className,
  links,
  richText,
  mobileVideo,
  mobileFallback,
  desktopVideo,
  desktopFallback,
  richTextMax,
  heroSize,
  disclaimerText,
  verticalAlign,
  horizontalAlign,
}: VideoHeroProps) => {
  return (
    <FullBleedHero className={className} heroSize={heroSize} verticalAlign={verticalAlign}>
      {mobileVideo?.url && (
        <div className="md:hidden">
          <video
            key={mobileVideo.url}
            className="absolute inset-0 size-full object-cover"
            poster={getMediaSizeUrlWithFallback(mobileFallback, 'smallAutoHeight')}
            muted
            playsInline
            loop
            controls={false}
            autoPlay
          >
            <source src={mobileVideo.url} />
            <track default kind="captions" />
          </video>
          <OverlayGradient className="z-20" mobile />
        </div>
      )}
      {desktopVideo?.url && (
        <div className="hidden md:block">
          <video
            key={desktopVideo.url}
            className="absolute inset-0 size-full object-cover"
            poster={getMediaSizeUrlWithFallback(desktopFallback, 'largeHero')}
            muted
            playsInline
            loop
            controls={false}
            autoPlay
          >
            <source src={desktopVideo.url} />
            <track default kind="captions" />
          </video>
          <OverlayGradient className="z-20" />
        </div>
      )}
      <Gutter className="relative z-30 h-full pt-[120px] md:pt-0">
        <HeroContent
          maxWidth={richTextMax}
          richText={richText}
          horizontalAlign={horizontalAlign}
          links={links}
          disclaimerText={disclaimerText}
        />
      </Gutter>
    </FullBleedHero>
  )
}
