import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { Gutter } from '@/app/_components/Gutter/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import {
  CollectionArchive,
  collectionArchiveCategoriesFragment,
  collectionArchivePopulatedDocs,
  collectionArchiveSelectedDocs,
} from '../../_components/CollectionArchive/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const archiveFragment = graphql(
  /* GraphQL */ `
    fragment archiveFragment on CmsArchive {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      populateBy
      relationTo
      categories {
        ...collectionArchiveCategoriesFragment
      }
      limit
      selectedDocs {
        ...collectionArchiveSelectedDocs
      }
      populatedDocs {
        ...collectionArchivePopulatedDocs
      }
      populatedDocsTotal
    }
  `,
  [
    collectionArchiveCategoriesFragment,
    collectionArchiveSelectedDocs,
    collectionArchivePopulatedDocs,
    cmsLinkFragment,
    cmsSectionFragment,
    cmsBlockSectionFragment,
  ],
)

export type ArchiveBlockProps = {
  id?: string
  data: FragmentOf<typeof archiveFragment>
}

export const ArchiveBlock = ({ data }: ArchiveBlockProps) => {
  const {
    relationTo,
    populateBy,
    limit,
    populatedDocs,
    populatedDocsTotal,
    selectedDocs,
    categories,
    section,
    ctas,
    invertBackground,
  } = readFragment(archiveFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <CollectionArchive
          populateBy={populateBy}
          relationTo={relationTo}
          populatedDocs={populatedDocs}
          populatedDocsTotal={populatedDocsTotal}
          selectedDocs={selectedDocs}
          categories={categories}
          limit={limit}
          sort="-publishedAt"
        />
      </Gutter>
    </BlockSection>
  )
}
