import { cn, InferComponentProps } from '@navinc/base-react-components'

import { getMediaSizeUrlWithFallback, MediaFragmentType } from '@/app/_components/Media/utils.js'
import { OverlayGradient } from '@/app/_components/OverlayGradient/index.jsx'

import { Gutter } from '../../_components/Gutter/index.js'
import { FullBleedHero } from '../shared/FullBleedHero.jsx'
import { HeroContent } from '../shared/HeroContent.jsx'

export type HighImpactHeroProps = {
  links: InferComponentProps<typeof HeroContent>['links']
  richText: InferComponentProps<typeof HeroContent>['richText']
  media?: MediaFragmentType | null
  richTextMax?: InferComponentProps<typeof HeroContent>['maxWidth']
  className?: string
  heroSize?: InferComponentProps<typeof FullBleedHero>['heroSize'] | null
  disclaimerText?: InferComponentProps<typeof HeroContent>['disclaimerText']
  horizontalAlign?: InferComponentProps<typeof HeroContent>['horizontalAlign']
  verticalAlign?: 'top' | 'bottom' | 'center' | null
}

export const HighImpactHero = ({
  className,
  links,
  richText,
  media,
  richTextMax,
  heroSize,
  disclaimerText,
  horizontalAlign,
  verticalAlign,
}: HighImpactHeroProps) => {
  const smallBg = getMediaSizeUrlWithFallback(media, 'portrait')
  const largeBg = getMediaSizeUrlWithFallback(media, 'largeHero')
  return (
    <FullBleedHero className={cn(className)} heroSize={heroSize} verticalAlign={verticalAlign}>
      {smallBg && (
        <div
          className="absolute inset-0 z-10 block bg-cover bg-center md:hidden"
          style={{ backgroundImage: `url("${smallBg}")` }}
        />
      )}
      {largeBg && (
        <div
          className="absolute inset-0 z-10 hidden bg-cover bg-center md:block"
          style={{ backgroundImage: `url("${largeBg}")` }}
        />
      )}
      <OverlayGradient className="z-20" />
      <Gutter className="relative z-30 h-full pt-[120px] md:pt-0">
        <HeroContent
          maxWidth={richTextMax}
          richText={richText}
          horizontalAlign={horizontalAlign}
          links={links}
          disclaimerText={disclaimerText}
        />
      </Gutter>
    </FullBleedHero>
  )
}
