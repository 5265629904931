import { cn } from '@navinc/base-react-components'
import { FragmentOf } from 'gql.tada'

import { cmsLinkFragment, CMSLinkFromFragment } from '../../_components/Link/index.js'

export type CallToActionLinksGroupProps = {
  links: { link: FragmentOf<typeof cmsLinkFragment> | null }[] | null
  isNested?: boolean
  ctaAlign?: 'left' | 'center'
}

export const CallToActionLinksGroup = ({ links, isNested, ctaAlign = 'center' }: CallToActionLinksGroupProps) => {
  const alignClass = {
    left: 'justify-start',
    center: 'justify-center',
  }[ctaAlign]
  return (
    <div className={cn(alignClass, 'mt-400 flex gap-100 p-0')}>
      {links?.map(({ link }, i) => {
        if (!link) return null

        return (
          <CMSLinkFromFragment
            key={i}
            data={link}
            density={isNested ? 'tight' : 'standard'}
            className="mx-100 w-full md:w-auto"
            data-testid="CallToAction:Button"
          />
        )
      })}
    </div>
  )
}
