import { cn, InferComponentProps } from '@navinc/base-react-components'

import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'

import { Gutter } from '../../_components/Gutter/index.jsx'
import { OverlayGradient } from '../../_components/OverlayGradient/index.jsx'
import { HeroContent } from '../shared/HeroContent.jsx'

export type BannerHeroProps = {
  links: InferComponentProps<typeof HeroContent>['links']
  richText: InferComponentProps<typeof HeroContent>['richText']
  media: Parameters<typeof getMediaSizeUrlWithFallback>[0]
  richTextMax?: InferComponentProps<typeof HeroContent>['maxWidth']
  className?: string
  disclaimerText?: InferComponentProps<typeof HeroContent>['disclaimerText']
}

export const BannerHero = ({ className, links, richText, media, richTextMax, disclaimerText }: BannerHeroProps) => {
  const smallBg = getMediaSizeUrlWithFallback(media, 'square')
  const largeBg = getMediaSizeUrlWithFallback(media, 'banner')
  return (
    <Gutter>
      <section
        className={cn(
          className,
          'relative w-full bg-inverseSurface px-200 text-inverseOnSurface md:px-500 mt-800 mb-400 md:h-[560px] bg-cover bg-center',
        )}
      >
        {smallBg && (
          <div
            className="absolute inset-0 z-10 block bg-cover bg-center md:hidden"
            style={{ backgroundImage: `url("${smallBg}")` }}
          />
        )}
        {largeBg && (
          <div
            className="absolute inset-0 z-10 hidden bg-cover bg-center md:block"
            style={{ backgroundImage: `url("${largeBg}")` }}
          />
        )}
        <div className="relative z-30 flex h-full flex-col md:flex-row">
          <div className="flex h-full flex-col py-400">
            <HeroContent maxWidth={richTextMax} richText={richText} links={links} disclaimerText={disclaimerText} />
          </div>
        </div>
        <OverlayGradient className="z-20" />
      </section>
    </Gutter>
  )
}
