import { Fragment } from 'react'

export const StarRating = ({ rating, text }: { rating?: number | null; text?: string | null }) => {
  const halfFilledStar = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 8.12488V15.9249L15.15 17.8499L14.325 14.2499L17.1 11.8499L13.45 11.5249L12 8.12488ZM12 18.2749L7.85 20.7749C7.66667 20.8915 7.475 20.9415 7.275 20.9249C7.075 20.9082 6.9 20.8415 6.75 20.7249C6.6 20.6082 6.48333 20.4665 6.4 20.2999C6.31667 20.1165 6.3 19.9165 6.35 19.6999L7.45 14.9749L3.775 11.7999C3.60833 11.6499 3.5 11.4832 3.45 11.2999C3.41667 11.0999 3.43333 10.9082 3.5 10.7249C3.56667 10.5415 3.66667 10.3915 3.8 10.2749C3.93333 10.1582 4.11667 10.0832 4.35 10.0499L9.2 9.62488L11.075 5.17488C11.1583 4.97488 11.2833 4.82488 11.45 4.72488C11.6333 4.62488 11.8167 4.57488 12 4.57488C12.1833 4.57488 12.3583 4.62488 12.525 4.72488C12.7083 4.82488 12.8417 4.97488 12.925 5.17488L14.8 9.62488L19.65 10.0499C19.8833 10.0832 20.0667 10.1582 20.2 10.2749C20.3333 10.3915 20.4333 10.5415 20.5 10.7249C20.5667 10.9082 20.575 11.0999 20.525 11.2999C20.4917 11.4832 20.3917 11.6499 20.225 11.7999L16.55 14.9749L17.65 19.6999C17.7 19.9165 17.6833 20.1165 17.6 20.2999C17.5167 20.4665 17.4 20.6082 17.25 20.7249C17.1 20.8415 16.925 20.9082 16.725 20.9249C16.525 20.9415 16.3333 20.8915 16.15 20.7749L12 18.2749Z"
        fill="currentColor"
      />
    </svg>
  )

  const filledStar = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 18.2749L7.85 20.7749C7.66667 20.8915 7.475 20.9415 7.275 20.9249C7.075 20.9082 6.9 20.8415 6.75 20.7249C6.6 20.6082 6.48333 20.4665 6.4 20.2999C6.31667 20.1165 6.3 19.9165 6.35 19.6999L7.45 14.9749L3.775 11.7999C3.60833 11.6499 3.5 11.4832 3.45 11.2999C3.41667 11.0999 3.43333 10.9082 3.5 10.7249C3.56667 10.5415 3.66667 10.3915 3.8 10.2749C3.93333 10.1582 4.11667 10.0832 4.35 10.0499L9.2 9.62488L11.075 5.17488C11.1583 4.97488 11.2833 4.82488 11.45 4.72488C11.6333 4.62488 11.8167 4.57488 12 4.57488C12.1833 4.57488 12.3583 4.62488 12.525 4.72488C12.7083 4.82488 12.8417 4.97488 12.925 5.17488L14.8 9.62488L19.65 10.0499C19.8833 10.0832 20.0667 10.1582 20.2 10.2749C20.3333 10.3915 20.4333 10.5415 20.5 10.7249C20.5667 10.9082 20.575 11.0999 20.525 11.2999C20.4917 11.4832 20.3917 11.6499 20.225 11.7999L16.55 14.9749L17.65 19.6999C17.7 19.9165 17.6833 20.1165 17.6 20.2999C17.5167 20.4665 17.4 20.6082 17.25 20.7249C17.1 20.8415 16.925 20.9082 16.725 20.9249C16.525 20.9415 16.3333 20.8915 16.15 20.7749L12 18.2749Z"
        fill="currentColor"
      />
    </svg>
  )

  const emptyStar = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m19.65 9.04-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75M12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28z"
        fill="currentColor"
      />
    </svg>
  )
  const ratingValue = rating ?? 5
  const fullStars = Math.floor(ratingValue)
  const hasHalfStar = ratingValue % 1 !== 0

  const starRating = (
    <span className="flex items-center text-primary">
      {[...Array(fullStars)].map((_, index) => (
        <Fragment key={index}>{filledStar}</Fragment>
      ))}
      {hasHalfStar && halfFilledStar}
      {[...Array(5 - fullStars - (hasHalfStar ? 1 : 0))].map((_, index) => (
        <Fragment key={index}>{emptyStar}</Fragment>
      ))}
    </span>
  )
  return (
    <div className="flex items-center gap-200 text-left">
      {rating ? (
        <>
          <span className="pt-50">{rating}</span> {starRating}
        </>
      ) : null}{' '}
      {text && <span className="pt-50">{text}</span>}
    </div>
  )
}
