import { Button } from '@navinc/base-react-components/wayfinder'

import { OfferCtaButton, OfferCtaButtonProps } from './OfferCtaButton.jsx'

export type OfferApplyCtaSectionProps = {
  url: OfferCtaButtonProps['url']
  buttonText: OfferCtaButtonProps['children']
}
export const OfferApplyCtaSection = ({ url, buttonText }: OfferApplyCtaSectionProps) => {
  return (
    <div className="flex flex-col items-center gap-200 border-t border-outlineVariant py-400 text-center">
      <OfferCtaButton url={url}>{buttonText}</OfferCtaButton>
      <div>
        <p className="body1-emphasized">Want to find out how you match up to this offer?</p>
        <p className="body2">Business owners who get matched through Nav are 3.5x more likely to get approved.</p>
      </div>
      <Button variant="primaryPlain" density="tight" asChild>
        <a href="https://app.nav.com">Get started</a>
      </Button>
    </div>
  )
}
