import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'
import { ResponsiveImage } from '@/app/_components/ResponsiveImage/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { RichText } from '../../_components/RichText/index.jsx'
import { Children } from '../../_components/RichText/serialize.js'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const bentoFragment = graphql(
  /* GraphQL */ `
    fragment bentoFragment on CmsBento {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      rows {
        cols {
          size
          richText
          bgMedia {
            ...CmsMediaFragment
          }
          overlay {
            size
            media {
              url
              alt
            }
          }
        }
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type BentoProps = {
  data: FragmentOf<typeof bentoFragment>
}

const getColumnSize = (size: string): string => {
  let colSpanClass = 'md:col-span-6'
  if (size === 'oneThird') colSpanClass = 'md:col-span-4'
  if (size === 'half') colSpanClass = 'md:col-span-6'
  if (size === 'twoThirds') colSpanClass = 'md:col-span-8'
  if (size === 'full') colSpanClass = 'md:col-span-12'
  return colSpanClass
}

export const BentoBlock = ({ data }: BentoProps) => {
  const { section, rows, ctas, invertBackground } = readFragment(bentoFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="mx-auto max-w-[1190px]">
          {rows?.map((row, i) => {
            return (
              <div
                key={`row-${i}`}
                className={`mb-400 grid h-auto grid-cols-12 gap-400 md:mb-500 md:gap-500 ${i === 0 ? 'md:h-[535px]' : 'md:h-[400px]'}`}
              >
                {row.cols?.map((col, i) => {
                  const colSpan = getColumnSize(col.size ?? 'half')

                  let richTextStyles = ''
                  let overlayStyles = ''

                  if (col.size === 'twoThirds') {
                    richTextStyles = `md:top-1/2 md:left-0 md:-translate-y-1/2 md:w-1/2 md:justify-center`
                    overlayStyles = `left-1/2 -translate-x-1/2 bottom-0 md:bottom-auto md:top-1/2 md:right-0 md:-translate-y-1/2 md:translate-x-0 md:w-1/2 h-1/2 md:h-auto flex items-center justify-center`
                  }

                  if (col.size === 'half' || col.size === 'oneThird') {
                    richTextStyles = `md:left-1/2 md:top-0 md:-translate-x-1/2 w-full`
                    overlayStyles = `bottom-0 w-full left-1/2 -translate-x-1/2 w-auto h-1/2 flex items-end justify-center`
                  }

                  return (
                    <div
                      key={`col-${i}`}
                      className={`col-span-12 ${colSpan} h-[440px] overflow-hidden rounded-200 md:h-full`}
                    >
                      <div className="relative z-10 size-full">
                        {col.bgMedia && (
                          <div
                            className="absolute inset-0 z-10 bg-cover bg-center"
                            style={{
                              backgroundImage: `url("${getMediaSizeUrlWithFallback(col.bgMedia, col.size === 'oneThird' ? 'portrait' : 'feature')}")`,
                            }}
                          />
                        )}
                        {col.overlay?.length ? (
                          <div className={`absolute z-20 ${overlayStyles}`}>
                            <ResponsiveImage responsiveMedia={col.overlay} className="max-h-full max-w-full" />
                          </div>
                        ) : null}

                        {col.richText ? (
                          <div
                            className={`absolute z-30 flex size-full flex-col p-500 text-inverseOnSurface ${richTextStyles}`}
                          >
                            <RichText content={col.richText as Children} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </Gutter>
    </BlockSection>
  )
}
