import { FragmentOf, readFragment } from 'gql.tada'
import { CMSLink, cmsLinkWithMetaFragment } from '../Link/index.jsx'
import { resolveHrefFragment } from '@/app/lib/resolveHref.js'

export type ResourceCardProps = {
  title: string
  caption?: string
  mediaUrl?: string
  alt?: string
  description?: string
  link?: (FragmentOf<typeof cmsLinkWithMetaFragment> & FragmentOf<typeof resolveHrefFragment>) | null
}

export const ResourceCard = ({ title, caption, mediaUrl, alt, description, link }: ResourceCardProps) => {
  const linkData = readFragment(cmsLinkWithMetaFragment, link)
  return (
    <div className="flex flex-1 flex-col gap-300 rounded-200 bg-white">
      {mediaUrl && (
        <>
          <div
            className="relative overflow-hidden rounded-200 bg-cover bg-center pb-[75%]"
            style={{ backgroundImage: `url("${mediaUrl}")` }}
            role="img"
            aria-label={alt}
          />
        </>
      )}
      <div className="flex flex-col gap-75 self-stretch">
        <h3 className="headline font-semibold text-onSurfaceVariant">{title}</h3>{' '}
        {description && <p className="body1 mt-100">{description}</p>}
        {caption && <p className="caption1 text-onSurfaceVariant">{caption}</p>}
        {linkData && (
          <div className="mt-100">
            <CMSLink {...linkData} density="tight" className="w-full md:w-auto">
              {linkData.label}
            </CMSLink>
          </div>
        )}
      </div>
    </div>
  )
}
