import { cn, InferComponentProps } from '@navinc/base-react-components'

import { getMediaSizeUrlWithFallback, MediaFragmentType } from '@/app/_components/Media/utils.js'
import { Gutter } from '../../_components/Gutter/index.js'
import { HeroContent } from '../shared/HeroContent.js'
import { FullBleedHero } from '../shared/FullBleedHero.jsx'

export type ProductHeroProps = {
  links: InferComponentProps<typeof HeroContent>['links']
  richText: InferComponentProps<typeof HeroContent>['richText']
  media?: MediaFragmentType | null
  richTextMax?: InferComponentProps<typeof HeroContent>['maxWidth']
  className?: string
  heroSize?: 'partial' | 'full' | null
  productImage: MediaFragmentType | null
  showReviews?: InferComponentProps<typeof HeroContent>['showReviews']
  reviewRating?: InferComponentProps<typeof HeroContent>['reviewRating']
  reviewText?: InferComponentProps<typeof HeroContent>['reviewText']
  disclaimerText?: InferComponentProps<typeof HeroContent>['disclaimerText']
}

export const ProductHero = ({
  className,
  links,
  richText,
  media,
  richTextMax,
  showReviews,
  reviewRating,
  reviewText,
  productImage,
  disclaimerText,
}: ProductHeroProps) => {
  const smallBg = getMediaSizeUrlWithFallback(media, 'portrait')
  const largeBg = getMediaSizeUrlWithFallback(media, 'largeHero')
  const productImageUrl = getMediaSizeUrlWithFallback(productImage, 'largeAutoHeight')

  return (
    <FullBleedHero
      className={cn(className)}
      variant="light"
      verticalAlign="top"
      heroSize="partial"
      style={{
        background: media ? undefined : 'radial-gradient(100% 100% at 0% 0%, #D9D9D9 0%, #777680 100%)',
        paddingBottom: '0 !important',
        justifyContent: 'space-between !important',
      }}
    >
      {smallBg && (
        <div
          className="absolute inset-0 z-10 block bg-cover bg-center md:hidden"
          style={{ backgroundImage: `url("${smallBg}")` }}
        />
      )}
      {largeBg && (
        <div
          className="absolute inset-0 z-10 hidden bg-cover bg-center md:block"
          style={{ backgroundImage: `url("${largeBg}")` }}
        />
      )}
      <Gutter className="relative z-30 h-full">
        <div className="flex h-full flex-col rlative z-20 items-center pt-400">
          <div className="flex h-full flex-col py-400 items-center">
            <HeroContent
              maxWidth={richTextMax}
              richText={richText}
              links={links}
              disclaimerText={disclaimerText}
              showReviews={showReviews}
              reviewRating={reviewRating}
              reviewText={reviewText}
            />
          </div>
        </div>
      </Gutter>
      {productImageUrl && (
        <div className="relative bottom-0 w-full self-center justify-self-end max-w-screen-sm mx-auto">
          <img src={productImageUrl} alt={productImage?.alt} className="max-h-full" />
        </div>
      )}
    </FullBleedHero>
  )
}
