import { ReactNode } from 'react'

export const OfferSpecs = ({
  specs,
  columns = 4,
}: {
  specs: Array<{ title: string; description: ReactNode } | null>
  columns?: number | null
}) => {
  return (
    <div className="row2 grid grid-cols-12 gap-300 border-y border-outlineVariant py-300">
      {specs.map((spec, index) => {
        if (!spec) {
          return null
        }
        return (
          <div key={index} className={`col-span-12 ${columns === 3 ? 'md:col-span-4' : 'md:col-span-3'}`}>
            <p className="caption1 mb-50 font-bold uppercase">{spec.title}</p>
            <div className="caption1">{spec.description}</div>
          </div>
        )
      })}
    </div>
  )
}
