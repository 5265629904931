import { MarketplaceLoanOffer } from './queries/loan-offers-query.js'
import { OfferApplyCtaSection } from './shared/OfferApplyCtaSection.jsx'
import { OfferCtaButton } from './shared/OfferCtaButton.jsx'
import { OfferDrawer } from './shared/OfferDrawer.jsx'
import { OfferProsCons } from './shared/OfferProsCons.jsx'
import { OfferSpecs } from './shared/OfferSpecs.jsx'
import { OfferTabs } from './shared/OfferTabs.jsx'
import { OfferFilter } from './shared/use-offer-filters.js'
import { OffersListLayout } from './shared/OffersListLayout.jsx'
import { OfferComparisonTable } from './OfferComparisonTable.jsx'

export const LoanOffer = ({ offer }: { offer: MarketplaceLoanOffer }) => {
  if (!offer) {
    return null
  }

  return (
    <div className=" rounded-200 border border-outlineVariant px-200 pt-200 shadow-elevation1">
      <div className="row1 grid grid-cols-12 gap-200">
        <div className="col-span-4 md:col-span-2">
          <div className="flex flex-col items-center gap-200 py-200">
            <img src={offer.image.high} alt={`${offer.name} Logo`} />
            <OfferCtaButton url={offer.applyUrl}>{offer.applyButtonText}</OfferCtaButton>
          </div>
        </div>
        <div className="col-span-8 md:col-span-10">
          <div className="p-200">
            <p className="title3-emphasized mb-200">{offer.name}</p>
            <p className="caption1 mb-100 rounded-100 bg-[#B8B4F3] p-100">{offer.navEditorial.summary}</p>
            <OfferProsCons pros={offer.navEditorial.pros} cons={offer.navEditorial.cons} />
          </div>
        </div>
      </div>

      <OfferSpecs
        specs={[
          { title: offer.specifics.fundingAmount.category, description: offer.specifics.fundingAmount.description },
          { title: offer.specifics.cost.category, description: offer.specifics.cost.description },
          { title: offer.specifics.repaymentTerms.category, description: offer.specifics.repaymentTerms.description },
          { title: offer.specifics.fundingSpeed.category, description: offer.specifics.fundingSpeed.description },
        ]}
      />

      <OfferDrawer>
        <OfferTabs
          tabs={[
            {
              value: 'recommendation',
              title: 'Our Recommendation',
              content: <p className="body1">{offer.description}</p>,
            },
            {
              value: 'terms',
              title: 'Terms & Conditions',
              content: <p className="body1">{offer.navEditorial.termsAndConditions}</p>,
              hide: !offer.navEditorial.termsAndConditions,
            },
          ]}
        />
        <OfferApplyCtaSection url={offer.applyUrl} buttonText={offer.applyButtonText} />
      </OfferDrawer>
    </div>
  )
}

export const LoanOffers = ({ offers, filters }: { offers: MarketplaceLoanOffer[]; filters: OfferFilter[] }) => {
  return (
    <>
      <OffersListLayout
        offers={offers}
        filters={filters}
        renderOffer={({ offer }) => <LoanOffer offer={offer as MarketplaceLoanOffer} />}
      />
      <OfferComparisonTable
        offers={offers.map((offer) => {
          return {
            title: (
              <div className="flex flex-col md:flex-row gap-100 md:items-center">
                <div className="h-auto max-w-[100px]">
                  <img src={offer.image.high} alt={`${offer.name} Logo`} />
                </div>{' '}
                <strong className="font-semibold">{offer.name}</strong>
              </div>
            ),
            fundingAmount: offer.specifics.fundingAmount.description,
            cost: offer.specifics.cost.description,
            repaymentTerms: offer.specifics.repaymentTerms.description,
            fundingSpeed: offer.specifics.fundingSpeed.description,
          }
        })}
        columns={[
          {
            key: 'title',
            label: 'Compare your options',
          },
          {
            key: 'fundingAmount',
            label: 'Funding Amount',
          },
          {
            key: 'cost',
            label: 'Cost',
          },
          {
            key: 'repaymentTerms',
            label: 'Repayment Terms',
          },
          {
            key: 'fundingSpeed',
            label: 'Funding Speed',
          },
        ]}
      />
    </>
  )
}
