import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'
import { pagePathname } from '@/app/lib/page-pathname.js'

import { CrossLinkCard } from '../../_components/CrossLinkCard/index.js'
import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { SmartLink } from '../../_components/SmartLink/SmartLink.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const crossLinksFragment = graphql(
  /* GraphQL */ `
    fragment crossLinksFragment on CmsCrossLinks {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      crossLinksCards {
        media {
          ...CmsMediaFragment
        }
        topic
        title
        selectedDoc {
          relationTo
          value {
            ... on CmsArticle {
              id
              slug
              title
              meta {
                title
                image {
                  ...CmsMediaFragment
                }
              }
            }
            ... on CmsPage {
              id
              slug
              parent {
                slug
              }
              title
              meta {
                title
                image {
                  ...CmsMediaFragment
                }
              }
            }
            ... on CmsGuide {
              id
              slug
              title
              meta {
                title
                image {
                  ...CmsMediaFragment
                }
              }
            }
            ... on CmsMarketplacePage {
              id
              slug
              parent {
                slug
              }
              title
              meta {
                title
                image {
                  ...CmsMediaFragment
                }
              }
            }
          }
        }
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type CrossLinksProps = {
  data: FragmentOf<typeof crossLinksFragment>
}

export const CrossLinks = ({ data }: CrossLinksProps) => {
  const { crossLinksCards: cards, section, ctas, invertBackground } = readFragment(crossLinksFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="flex justify-center">
          <ul className="mx-auto my-0 flex w-full max-w-[1190px] flex-col justify-evenly gap-500 p-0 md:w-fit md:flex-row">
            {cards?.map((card, index) => {
              const result = card.selectedDoc

              if (result?.value) {
                const relationTo = result.relationTo

                const title = card.title ?? result.value.title ?? result.value.meta?.title ?? ''

                const href = pagePathname(relationTo, result.value)
                return (
                  <li key={index} className="" data-testid={`crossLinkCard:${card.topic}`}>
                    <SmartLink to={href} title={title}>
                      <CrossLinkCard topic={card.topic} title={title} media={card.media || result.value.meta?.image} />
                    </SmartLink>
                  </li>
                )
              }

              return null
            })}
          </ul>
        </div>
      </Gutter>
    </BlockSection>
  )
}
