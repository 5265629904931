import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import iphoneFrameImg from './images/iphone-frame.webp'
import macbookFrameImg from './images/macbook-frame.webp'

export const devicePreviewFragment = graphql(
  /* GraphQL */ `
    fragment devicePreviewFragment on CmsDevicePreview {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      phoneMedia {
        ...CmsMediaFragment
      }
      laptopMedia {
        ...CmsMediaFragment
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type DevicePreviewProps = {
  data: FragmentOf<typeof devicePreviewFragment>
}

export const DevicePreview = ({ data }: DevicePreviewProps) => {
  const { section, ctas, laptopMedia, phoneMedia, invertBackground } = readFragment(devicePreviewFragment, data)

  const textContent = (
    <div className="TextContent relative z-30 col-span-12 flex items-center justify-center px-400 text-center md:col-span-6 md:text-left">
      <div className="flex flex-col items-center">
        <SectionHeader data={section} links={ctas} textAlignClassName="text-center md:text-left" />
      </div>
    </div>
  )

  const iphoneRender = (
    <div className="relative h-[238px] w-[120px] md:h-[534px] md:w-[280px]">
      <div className="absolute left-[10px] top-75 z-10 h-[218px] w-[100px] bg-surfaceBright md:left-[23px] md:top-200 md:h-[508px] md:w-[234px]">
        <div className="relative size-full">
          {phoneMedia && (
            <div
              className="z-10 size-full bg-cover bg-left-top"
              style={{ backgroundImage: `url("${getMediaSizeUrlWithFallback(phoneMedia, 'largeAutoHeight')}")` }}
            />
          )}
        </div>
      </div>
      <img src={iphoneFrameImg} alt="IPhone frame frame" className="absolute left-0 top-0 z-20" />
    </div>
  )

  const laptopRender = (
    <div className="relative h-[375px] w-[574px] md:h-[910px] md:w-[1495px]">
      <div className="absolute left-[58px] top-[41px] z-10 h-[296px] w-[458px] bg-surfaceBright md:left-[116px] md:top-[82px] md:h-[588px] md:w-[908px]">
        <div className="relative size-full">
          {laptopMedia && (
            <div
              className="z-10 size-full bg-cover bg-left-top"
              style={{ backgroundImage: `url("${getMediaSizeUrlWithFallback(laptopMedia, 'largeAutoHeight')}")` }}
            />
          )}
        </div>
      </div>
      <img src={macbookFrameImg} alt="Macbook frame" className="absolute left-0 top-0 z-20 w-[574px] md:w-[1140px]" />
    </div>
  )

  const imageContent = (
    <div className="ImageContent relative col-span-12 flex justify-center overflow-hidden md:col-span-6 md:overflow-visible">
      <div className="relative h-[380px] w-[574px] md:h-[744px] md:w-[708px]">
        {/* Macbook  */}
        <div className="absolute z-10 w-full">{laptopRender}</div>

        {/* Iphone  */}
        <div className="absolute bottom-0 z-20">{iphoneRender}</div>
      </div>
    </div>
  )

  return (
    <BlockSection invertBackground={invertBackground} className="px-0" section={section}>
      <Gutter className="">
        <div className="DevicePreview min-h-none flex flex-col gap-300 py-400 md:grid md:min-h-[910px] md:grid-cols-12 md:py-800">
          {textContent}
          {imageContent}
        </div>
      </Gutter>
    </BlockSection>
  )
}
