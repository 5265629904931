import { createRef, forwardRef, ReactNode, RefObject, useRef } from 'react'

import { cmsBlockSectionFragment, BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { getMediaSizeUrlWithFallback, MediaFragmentType } from '@/app/_components/Media/utils.js'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'
import { TableOfContents } from '@/app/_components/TableOfContents/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { LoaderBlocksData } from '../Blocks.jsx'
import { RichTextNestedBlocks, richTextNestedBlocksFragment } from './RichTextNestedBlocks.jsx'

const slug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[\s\W]+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const richTextSectionFragment = graphql(
  /* GraphQL */ `
    fragment richTextSectionFragment on CmsRichTextSection {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      richTextSections {
        header
        featuredImage {
          ...CmsMediaFragment
        }
        content {
          ...richTextNestedBlocksFragment
        }
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, richTextNestedBlocksFragment, CmsMediaFragment, cmsBlockSectionFragment],
)

export type RichTextSectionProps = {
  data: FragmentOf<typeof richTextSectionFragment>
  loaderBlocksData?: LoaderBlocksData
}

type ContentComponentProps = {
  header: string
  children: ReactNode
  featuredImage?: MediaFragmentType | null | undefined
}

const ContentComponent = forwardRef<HTMLDivElement, ContentComponentProps>(
  ({ header, children, featuredImage }, ref) => {
    const imageUrl = getMediaSizeUrlWithFallback(featuredImage, 'largeAutoHeight')

    return (
      <div ref={ref} id={slug(header)} className="pt-200">
        {imageUrl && (
          <div className="relative z-10 my-200 overflow-hidden rounded-200">
            <img src={imageUrl} alt={featuredImage?.alt} />
          </div>
        )}
        <h2 id={`${slug(header)}-heading`} className="display2-emphasized mb-200 font-semibold">
          {header}
        </h2>
        {children}
      </div>
    )
  },
)

export const RichTextSection = ({ data, loaderBlocksData }: RichTextSectionProps) => {
  const { section, richTextSections, ctas, invertBackground } = readFragment(richTextSectionFragment, data)

  const sectionRefsRef = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({})

  richTextSections?.forEach(({ header }) => {
    if (header) {
      sectionRefsRef.current[slug(header)] ??= createRef<HTMLDivElement>()
    }
  })

  const tableOfContents =
    richTextSections
      ?.map(({ header }) => (header ? { text: header, sectionRefs: sectionRefsRef.current } : null))
      .filter(
        (item): item is { text: string; sectionRefs: { [key: string]: RefObject<HTMLDivElement> } } => item !== null,
      ) || []

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="Wrapper grid w-full grid-cols-12 desktop:gap-500">
          <aside className="sticky col-span-12 bg-surface md:col-span-4">
            <TableOfContents headings={tableOfContents} sectionRefs={sectionRefsRef.current} />
          </aside>

          <div className="Content col-span-12 flex flex-col md:col-span-8">
            {richTextSections &&
              richTextSections.map(({ header, content, featuredImage }, index) => {
                if (!header) {
                  return null
                }
                return (
                  <ContentComponent
                    key={index}
                    featuredImage={featuredImage}
                    header={header as string}
                    ref={sectionRefsRef.current[header ? slug(header) : '']}
                  >
                    <RichTextNestedBlocks data={content} loaderBlocksData={loaderBlocksData} />
                  </ContentComponent>
                )
              })}
          </div>
        </div>
      </Gutter>
    </BlockSection>
  )
}
