import { cmsBlockSectionFragment, BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { Gutter } from '@/app/_components/Gutter/index.jsx'
import { cmsLinkFragment } from '@/app/_components/Link/index.jsx'
import { CreditCardOffer } from '@/app/_components/Offers/CreditCardOffers.jsx'
import { LoanOffer } from '@/app/_components/Offers/LoanOffers.jsx'
import { ProductOffer } from '@/app/_components/Offers/ProductOffers.jsx'
import { MarketplaceCreditCard } from '@/app/_components/Offers/queries/credit-card-offers-query.js'
import { MarketplaceLoanOffer } from '@/app/_components/Offers/queries/loan-offers-query.js'
import { MarketplaceProductOption } from '@/app/_components/Offers/queries/product-category-offers.query.js'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { LoaderBlocksData } from '../Blocks.jsx'

export const offerBlockFragment = graphql(
  /* GraphQL */ `
    fragment offerBlockFragment on CmsOfferBlock {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      marketplaceOffer {
        category
        offerId
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

type OfferBlockProps = {
  data: FragmentOf<typeof offerBlockFragment>
  loaderBlocksData?: LoaderBlocksData
  isNested?: boolean
}

export const OfferBlock = ({ data, loaderBlocksData, isNested }: OfferBlockProps) => {
  if (!loaderBlocksData?.offers) {
    return null
  }
  const { marketplaceOffer, invertBackground, section, ctas } = readFragment(offerBlockFragment, data)

  if (!marketplaceOffer?.category) {
    return null
  }
  let offerComponent
  if (marketplaceOffer.category === 'businessCreditCards') {
    const offer = loaderBlocksData.offers?.creditCards?.businessCreditCards?.find(
      (creditCard: MarketplaceCreditCard) => `${creditCard.id}` === `${marketplaceOffer.offerId}`,
    )

    if (offer) {
      offerComponent = <CreditCardOffer offer={offer} />
    }
  }

  if (marketplaceOffer.category === 'businessLoans') {
    const offer = loaderBlocksData.offers?.loans?.loans?.find(
      (loan: MarketplaceLoanOffer) => `${loan.id}` === `${marketplaceOffer.offerId}`,
    )

    if (!offer) {
      return null
    }
    offerComponent = <LoanOffer offer={offer} />
  }

  if (loaderBlocksData.offers?.productCategories?.category?.toLowerCase() === marketplaceOffer.category.toLowerCase()) {
    const offer = loaderBlocksData.offers.productCategories.productOptions?.find(
      (productOption: MarketplaceProductOption) => productOption.id === marketplaceOffer.offerId,
    )

    if (!offer) {
      return null
    }
    offerComponent = <ProductOffer offer={offer} />
  }

  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested} section={section}>
      <SectionHeader data={section} links={ctas} />
      <Gutter>{offerComponent}</Gutter>
    </BlockSection>
  )
}
