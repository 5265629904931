import { Tabs } from '@navinc/base-react-components/wayfinder'
import { ReactNode, useState } from 'react'

type OfferTab = {
  value: string
  hide?: boolean
  title: string
  content: ReactNode
}

type OfferTabsProps = {
  tabs: Array<OfferTab>
  defaultValue?: string
}

export const OfferTabs = ({ tabs, defaultValue }: OfferTabsProps) => {
  const [tabValue, setTabValue] = useState(defaultValue ?? tabs[0]?.value)

  return (
    <Tabs defaultValue={defaultValue} value={tabValue} onValueChange={(value) => setTabValue(value)}>
      <Tabs.List>
        {tabs.map((tab) => {
          return (
            !tab.hide && (
              <Tabs.Tab key={tab.value} value={tab.value} className="body2-emphasized py-150">
                {tab.title}
              </Tabs.Tab>
            )
          )
        })}
      </Tabs.List>

      {tabs.map((tab) => {
        return (
          !tab.hide && (
            <Tabs.Content key={tab.value} value={tab.value}>
              <div className="body1 py-400">{tab.content}</div>
            </Tabs.Content>
          )
        )
      })}
    </Tabs>
  )
}
