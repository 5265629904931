import { CmsMediaFragment } from '@/app/_graphql/media.js'
import { cmsVideoFragment } from '@/app/_graphql/videos.js'
import { BannerHero } from '@/app/_heros/BannerHero/index.jsx'
import { ProductHero } from '@/app/_heros/ProductHero/index.jsx'
import { VideoHero } from '@/app/_heros/VideoHero/index.jsx'

import { HighImpactHero } from '../../_heros/HighImpact/index.js'
import classes from '../../_heros/shared/hero.module.scss'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { cmsLinkFragment } from '../Link/index.jsx'
import { Children } from '../RichText/serialize.jsx'
import { MediumImpactHero } from '@/app/_heros/MediumImpact/index.jsx'

export const heroFragment = graphql(
  /* GraphQL */ `
    fragment heroFragment on CmsHero {
      type
      richText
      richTextMax
      mobileVideo {
        ...cmsVideoFragment
      }
      desktopVideo {
        ...cmsVideoFragment
      }
      mobileFallback {
        ...CmsMediaFragment
      }
      desktopFallback {
        ...CmsMediaFragment
      }
      hzAlign
      vertAlign
      links {
        link {
          ...cmsLinkFragment
        }
      }
      media {
        ...CmsMediaFragment
      }
      productImage {
        ...CmsMediaFragment
      }
      showReviews
      reviewRating
      reviewText
      heroSize
      disclaimerText
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsVideoFragment],
)

type HeroProps = {
  data: FragmentOf<typeof heroFragment> | null
}

export const Hero = (props: HeroProps) => {
  if (!props.data) return null

  const data = readFragment(heroFragment, props.data)
  const {
    type,
    media,
    links,
    richText,
    mobileVideo,
    desktopVideo,
    mobileFallback,
    desktopFallback,
    richTextMax,
    productImage,
    showReviews,
    reviewRating,
    reviewText,
    heroSize,
    disclaimerText,
    hzAlign,
    vertAlign,
  } = data

  if (!type || type === 'none') return null

  if (type === 'videoHero') {
    return (
      <VideoHero
        className={classes.videoHero}
        links={links}
        richText={richText as Children}
        mobileVideo={mobileVideo}
        mobileFallback={mobileFallback}
        desktopVideo={desktopVideo}
        desktopFallback={desktopFallback}
        richTextMax={richTextMax}
        heroSize={heroSize}
        disclaimerText={disclaimerText}
        verticalAlign={vertAlign}
        horizontalAlign={hzAlign}
      />
    )
  }

  if (type === 'bannerHero') {
    return (
      <BannerHero
        className={classes.bannerHero}
        links={links}
        richText={richText as Children}
        media={media}
        richTextMax={richTextMax}
        disclaimerText={disclaimerText}
      />
    )
  }

  if (type === 'productHero') {
    return (
      <ProductHero
        className={classes.productHero}
        links={links}
        richText={richText as Children}
        richTextMax={richTextMax}
        productImage={productImage}
        showReviews={showReviews}
        reviewRating={reviewRating}
        reviewText={reviewText}
        media={media}
      />
    )
  }

  if (type === 'highImpact') {
    return (
      <HighImpactHero
        className={classes.highImpactHero}
        links={links}
        richText={richText as Children}
        media={media}
        richTextMax={richTextMax}
        heroSize={heroSize}
        disclaimerText={disclaimerText}
        horizontalAlign={hzAlign}
        verticalAlign={vertAlign}
      />
    )
  }

  if (type === 'mediumImpact') {
    return (
      <MediumImpactHero
        className={classes.mediumImpactHero}
        links={links}
        richText={richText as Children}
        media={media}
        richTextMax={richTextMax}
        heroSize={heroSize}
        disclaimerText={disclaimerText}
        horizontalAlign={hzAlign}
        verticalAlign={vertAlign}
      />
    )
  }
}
