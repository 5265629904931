import { Icon } from '@navinc/base-react-components/wayfinder'

export const Feature = ({
  iconName,
  title,
  description,
  fillIcon,
}: {
  iconName: string
  title: string
  description: string
  fillIcon?: boolean
}) => {
  return (
    <div className="flex w-full max-w-[300px] flex-col items-center gap-200 text-center">
      <Icon name={iconName} className="text-[48px] text-prime" isFilled={fillIcon} />
      <div className="flex flex-col items-center gap-75 text-inherit">
        <p className="title3-emphasized">{title}</p>
        <p className="body2">{description}</p>
      </div>
    </div>
  )
}
