import { cn, InferComponentProps } from '@navinc/base-react-components'

import { RichText } from '@/app/_components/RichText/index.jsx'
import { Children } from '@/app/_components/RichText/serialize.jsx'
import { StarRating } from '@/app/_components/StarRating/index.jsx'
import { HeroLinksGroup } from './HeroLinksGroup.jsx'
import { HeroDisclaimer } from './HeroDisclaimer.jsx'

export const HeroContent = ({
  maxWidth,
  richText,
  horizontalAlign,
  showReviews,
  reviewRating,
  reviewText,
  links,
  disclaimerText,
}: {
  maxWidth?: number | null
  richText: InferComponentProps<typeof RichText>['content']
  horizontalAlign?: 'left' | 'right' | 'center' | null
  showReviews?: boolean | null
  reviewRating?: InferComponentProps<typeof StarRating>['rating'] | null
  reviewText?: InferComponentProps<typeof StarRating>['text'] | null
  links?: InferComponentProps<typeof HeroLinksGroup>['links'] | null
  disclaimerText?: InferComponentProps<typeof HeroDisclaimer>['children'] | null
}) => {
  const horizontalAlignClass = {
    left: 'items-start',
    right: 'items-end',
    center: 'items-center',
  }[horizontalAlign ?? 'left']
  return (
    <>
      <div
        className={cn('text-inherit relative z-20 flex size-full flex-col justify-end', horizontalAlignClass)}
        data-testid="hero:content"
      >
        <div
          style={{
            maxWidth: maxWidth ? `${maxWidth}px` : 'none',
          }}
        >
          <RichText content={richText as Children} className={cn('title3 flex flex-col', horizontalAlignClass)} />
        </div>
      </div>
      {showReviews && reviewRating !== null && (
        <div className="mt-200">
          <StarRating rating={reviewRating} text={reviewText} />
        </div>
      )}
      {Array.isArray(links) && <HeroLinksGroup links={links} horizontalAlign={horizontalAlign} />}
      {disclaimerText && <HeroDisclaimer horizontalAlign={horizontalAlign}>{disclaimerText}</HeroDisclaimer>}
    </>
  )
}
