import { ReactNode } from 'react'
import { FilterDialog } from '../shared/FilterDialog/index.jsx'
import { OfferFilters } from '../shared/OfferFilters.jsx'
import { OfferPaginationControls } from '../shared/OfferPaginationControls.jsx'
import { OfferResults } from '../shared/OfferResults.jsx'
import { OfferFilter, useOfferFilters } from '../shared/use-offer-filters.js'
import { useShowMoreLess } from '../shared/use-show-more-less.js'
import { InferComponentProps } from '@navinc/base-react-components'
import { ProductOffer } from '../ProductOffers.jsx'
import { CreditCardOffer } from '../CreditCardOffers.jsx'
import { LoanOffer } from '../LoanOffers.jsx'

type OfferListOffer =
  | InferComponentProps<typeof ProductOffer>['offer']
  | InferComponentProps<typeof CreditCardOffer>['offer']
  | InferComponentProps<typeof LoanOffer>['offer']

export const OffersListLayout = ({
  offers,
  filters,
  renderOffer,
}: {
  offers: OfferListOffer[]
  filters: OfferFilter[]
  renderOffer: ({ offer }: { offer: OfferListOffer }) => ReactNode
}) => {
  const { handleClearFilters, activeFilters, createTagFilter } = useOfferFilters({ filters })

  const filteredOffersList = offers.filter(createTagFilter({ activeFilters })) ?? []
  const initialItemsToShow = 6
  const { currentItems, showMoreItems, showLessItems, hasMoreItems, hasLessItems } = useShowMoreLess(
    filteredOffersList,
    initialItemsToShow,
  )

  const offerResults = (
    <OfferResults
      visibleCount={currentItems.length}
      handleClearFilters={handleClearFilters}
      activeFilters={activeFilters}
      offersListCount={filteredOffersList.length}
    />
  )

  const renderedFilters = <OfferFilters filters={filters} />

  return (
    <div className="grid w-full grid-cols-12 gap-300">
      <aside className="hidden md:block col-span-3">{renderedFilters}</aside>

      <div className="block md:hidden">
        <FilterDialog>{renderedFilters}</FilterDialog>
      </div>

      <div className="col-span-12 md:col-span-9">
        {offerResults}
        <ul className="flex w-full flex-col gap-200">
          {currentItems.map((offer, index) => {
            if (!offer) {
              return null
            }
            return <li key={index}>{renderOffer({ offer })}</li>
          })}
        </ul>

        <div className="flex items-center gap-200 py-200">
          {offerResults}
          <OfferPaginationControls
            hasMoreItems={hasMoreItems}
            showMoreItems={showMoreItems}
            hasLessItems={hasLessItems}
            showLessItems={showLessItems}
          />
        </div>
      </div>
    </div>
  )
}
