import { CopyVariant as Copy, Theme } from '@navinc/base-react-components'
import { styled } from 'styled-components'

const getChannelFill = (checked?: boolean, color?: string, theme?: Theme, disabled?: boolean) => {
  if (disabled) return theme?.onSurfaceDim
  if (color && checked) return color
  return checked ? theme?.primary : theme?.outlineVariant
}

const ToggleContainer = styled.div<{ checked?: boolean; color?: string; theme?: Theme; disabled?: boolean }>`
  display: flex;
  justify-content: space-evenly;
  gap: 0;
  border: 1px solid ${({ checked, color, theme, disabled }) => getChannelFill(checked, color, theme, disabled)};
  border-radius: 24px;
  height: 38px;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(${({ checked }) => (checked ? '16px' : '0')});
  appearance: none;
  margin: 0;
  font-size: inherit;
  padding: 10px 4px;
  z-index: 0; /* make sure the checkbox is below the icon */
  &:focus {
    outline: 0;
    box-shadow: none;
  }
`

const ToggleElement = styled.div<{ checked: boolean; theme: Theme }>`
  height: 28px;
  border-radius: 22px;
  background: ${({ checked, theme }) => (checked ? theme.colors.primary : 'none')};
  color: ${({ checked, theme }) => (checked ? theme.colors.onPrimary : theme.colors.onSurfaceVariant)};
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.mediaQuery.largerThanTablet} {
    width: 290px;
  }
`

const StyledToggleContainer = styled(ToggleContainer)`
  margin-top: 28px;
  grid-column: 1 / -1;
  align-items: center;
  place-self: center;
  max-width: 318px;
  ${({ theme }) => theme.mediaQuery.largerThanTablet} {
    grid-column: 2 / span 4;

    justify-content: space-between;
  }
`

const MonthlyToggle = styled(ToggleElement)`
  ${({ theme }) => theme.mediaQuery.largerThanTablet} {
    width: 113px;
  }
`

const QuarterlyToggle = styled(MonthlyToggle)`
  gap: 4px;
  align-items: baseline;
  padding-top: 4px;
  ${({ theme }) => theme.mediaQuery.largerThanTablet} {
    width: 181px;
  }
`

export const BillingTermsToggle = ({
  monthlySelected,
  onMonthlySelected,
  onQuarterlySelected,
}: {
  monthlySelected: boolean
  onMonthlySelected: () => void
  onQuarterlySelected: () => void
}) => {
  return (
    <StyledToggleContainer role="radiogroup">
      <MonthlyToggle
        checked={monthlySelected}
        aria-checked={monthlySelected}
        tabIndex={0}
        onKeyDown={() => {}}
        role="radio"
        onClick={() => {
          onMonthlySelected()
        }}
        data-testid="prime:plan:frequency:monthly"
        className={monthlySelected ? 'bg-prime' : ''}
      >
        <Copy size="body2" variant="emphasized" color={monthlySelected ? 'onPrime' : 'onSurfaceVariant'}>
          Pay monthly
        </Copy>
      </MonthlyToggle>
      <QuarterlyToggle
        checked={!monthlySelected}
        aria-checked={!monthlySelected}
        tabIndex={0}
        onKeyDown={() => {}}
        role="radio"
        onClick={() => {
          onQuarterlySelected()
        }}
        data-testid="prime:plan:frequency:quarterly"
        className={!monthlySelected ? 'bg-prime' : ''}
      >
        <Copy size="body2" variant="emphasized" color={!monthlySelected ? 'onPrime' : 'onSurfaceVariant'}>
          Pay quarterly
        </Copy>
        <Copy size="caption2" variant="uppercase" color={!monthlySelected ? 'onPrime' : 'super'}>
          save 20%
        </Copy>
      </QuarterlyToggle>
    </StyledToggleContainer>
  )
}
