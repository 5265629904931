import { cn } from '@navinc/base-react-components'

import { useResponsiveMedia, UseResponsiveMediaProp } from '../Media/utils.js'

export const ResponsiveImage = ({
  responsiveMedia,
  className,
}: {
  className?: string
  responsiveMedia: UseResponsiveMediaProp<{ url: string | null; alt: string } | null>
}) => {
  const { smallMedia, mediumMedia, largeMedia } = useResponsiveMedia(responsiveMedia)

  return (
    <>
      {/* small image */}
      {smallMedia?.url && (
        <img
          src={smallMedia.url}
          alt={smallMedia.alt ?? ''}
          className={cn('ResponsiveSmallSize block md:hidden', className)}
        />
      )}
      {/* medium image */}
      {mediumMedia?.url && (
        <img
          src={mediumMedia.url}
          alt={mediumMedia.alt ?? ''}
          className={cn('ResponsiveMediumSize hidden md:block lg:hidden', className)}
        />
      )}
      {/* large image */}
      {largeMedia?.url && (
        <img
          src={largeMedia.url}
          alt={largeMedia.alt ?? ''}
          className={cn('ResponsiveLargeSize hidden md:hidden lg:block', className)}
        />
      )}
    </>
  )
}
