import { getMediaSizeUrlWithFallback, MediaFragmentType } from '../Media/utils.js'

const ArrowIcon = ({ renderAs = 'svg' }: { renderAs?: 'icon' | 'svg' }) => {
  if (renderAs === 'svg') {
    return (
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.6665 0.984375L16.6821 9L8.6665 17.0156L7.26025 15.6094L12.8384 9.98438H0.650879V8.01562H12.8384L7.26025 2.39062L8.6665 0.984375Z"
          fill="currentcolor"
        />
      </svg>
    )
  }
}

export const CrossLinkCard = ({
  media,
  topic,
  title,
}: {
  imageUrl?: string | null
  topic: string | null
  title: string | null
  media?: MediaFragmentType | null
}) => {
  const smallBg = getMediaSizeUrlWithFallback(media, 'feature')
  const largeBg = getMediaSizeUrlWithFallback(media, 'portrait')
  return (
    <div className="relative gap-300 overflow-hidden rounded-200 md:max-w-[320px] lg:max-w-[450px]">
      <div className="relative cursor-pointer bg-inverseSurface  p-300">
        <div
          className="absolute inset-0 block bg-cover bg-center bg-no-repeat md:hidden"
          style={{ backgroundImage: `url("${smallBg}")` }}
        />
        <div
          className="absolute inset-0 hidden bg-cover bg-center bg-no-repeat md:block"
          style={{ backgroundImage: `url("${largeBg}")` }}
        />
        <div className="pointer-events-none absolute inset-0 z-10 size-full bg-gradient-to-b from-onSurface/50 to-transparent" />
        <div className="relative z-20 flex size-full min-h-[350px] flex-col justify-between gap-300 md:min-h-[400px] md:min-w-[280px] lg:min-h-[450px] lg:min-w-[340px] lg:flex-row">
          <div className="flex flex-col items-start gap-100">
            <p className="caption1-uppercase text-white">{topic}</p>
            <p className="title2-emphasized text-white">{title}</p>
          </div>
          <div
            className="bg-gray-300/30 inline-flex items-center justify-center self-end rounded-200 border border-white p-150 text-white shadow-elevation1 backdrop-blur-md transition-all duration-300"
            data-testid="crossLinkCard:arrow"
          >
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
