import { cn } from '@navinc/base-react-components'
import { CSSProperties, ReactNode } from 'react'

export type FullBleedHeroProps = {
  heroSize?: 'partial' | 'full' | null
  children: ReactNode
  className?: string
  variant?: 'light' | 'dark' | null
  verticalAlign?: 'bottom' | 'center' | 'top' | null
  style?: CSSProperties
}
export const FullBleedHero = ({
  heroSize,
  children,
  className,
  variant,
  verticalAlign,
  style = {},
}: FullBleedHeroProps) => {
  const variantClass = {
    light: 'bg-surface text-onSurface',
    dark: 'bg-inverseSurface text-inverseOnSurface',
  }[variant ?? 'dark']

  const verticalAlignClass = {
    bottom: 'justify-end',
    top: 'justify-start',
    center: 'justify-center',
  }[verticalAlign ?? 'bottom']

  return (
    <section
      className={cn(
        heroSize === 'partial' ? 'md:min-h-[75vh]' : 'md:min-h-screen',
        'relative w-full pt-[190px] px-200 pb-400 md:px-500 md:pb-1000 md:pt-[120px] flex flex-col',
        variantClass,
        verticalAlignClass,
        className,
      )}
      style={style}
    >
      {children}
    </section>
  )
}
