import { RichText } from '@/app/_components/RichText/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import { cmsBlockSectionFragment, BlockSection } from '../../_components/BlockSection/index.jsx'
import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { Children } from '../../_components/RichText/serialize.js'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const legalBlockFragment = graphql(
  /* GraphQL */ `
    fragment legalBlockFragment on CmsLegalBlock {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      legal {
        ... on CmsLegal {
          richText
        }
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type LegalBlockProps = {
  data: FragmentOf<typeof legalBlockFragment>
}

export const LegalBlock = ({ data }: LegalBlockProps) => {
  const { section, legal, ctas, invertBackground } = readFragment(legalBlockFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="mx-auto max-w-[1190px]">
          <RichText content={legal?.richText as Children} />
        </div>
      </Gutter>
    </BlockSection>
  )
}
