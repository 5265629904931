import { CmsMediaFragment } from '@/app/_graphql/media.js'
import { cmsBlockSectionFragment, BlockSection } from '../../_components/BlockSection/index.jsx'
import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { cmsSectionFragment, SectionHeader } from '../../_components/SectionHeader/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'

export const imageBlockFragment = graphql(
  /* GraphQL */ `
    fragment imageBlockFragment on CmsImageBlock {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      image {
        ...CmsMediaFragment
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type ImageBlockProps = {
  data: FragmentOf<typeof imageBlockFragment>
  isNested?: boolean
}

export const ImageBlock = ({ data, isNested }: ImageBlockProps) => {
  const { section, image, ctas, invertBackground } = readFragment(imageBlockFragment, data)

  const imageUrl = getMediaSizeUrlWithFallback(image, 'largeAutoHeight')
  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />

        {imageUrl && (
          <div className="relative z-10 rounded-200 overflow-hidden">
            <img src={imageUrl} alt={image?.alt} />
          </div>
        )}
      </Gutter>
    </BlockSection>
  )
}
