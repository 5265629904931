import classNames from 'classnames'

const defaultLabels = {
  plural: 'Docs',
  singular: 'Doc',
}

const defaultCollectionLabels = {
  articles: {
    plural: 'Articles',
    singular: 'Article',
  },
}

export const PageRange = (props: {
  className?: string
  collection?: string | null
  collectionLabels?: {
    plural?: string
    singular?: string
  }
  currentPage?: number
  limit?: number | null
  totalDocs?: number
}) => {
  const { className, collection, collectionLabels: collectionLabelsFromProps, currentPage, limit, totalDocs } = props

  let indexStart = (currentPage ? currentPage - 1 : 1) * (limit || 1) + 1
  if (totalDocs && indexStart > totalDocs) indexStart = 0

  let indexEnd = (currentPage || 1) * (limit || 1)
  if (totalDocs && indexEnd > totalDocs) indexEnd = totalDocs

  const { plural, singular } =
    collectionLabelsFromProps ||
    (collection &&
      collection in defaultCollectionLabels &&
      defaultCollectionLabels[collection as keyof typeof defaultCollectionLabels]) ||
    defaultLabels ||
    {}

  return (
    <div className={classNames(className, 'flex', 'items-center')}>
      {(typeof totalDocs === 'undefined' || totalDocs === 0) && 'Search produced no results.'}
      {typeof totalDocs !== 'undefined' &&
        totalDocs > 0 &&
        `Showing ${indexStart}${indexStart > 0 ? ` - ${indexEnd}` : ''} of ${totalDocs} ${
          totalDocs > 1 ? plural : singular
        }`}
    </div>
  )
}
