import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { useFeatureFlag } from '../../lib/feature-flagging/context.js'
import { baseBlocksFragments, baseBlocksFragmentString } from '../base-blocks.js'
import { Blocks } from './blocks.jsx'

export const experimentFragment = graphql(
  /* GraphQL */ `
    fragment experimentFragment on CmsExperiment {
      featureFlag {
        key
        value
      }
      layout {
        __typename
        ${baseBlocksFragmentString}
      }
    }
  `,
  baseBlocksFragments,
)

export type ExperimentProps = {
  data: FragmentOf<typeof experimentFragment>
}

export const Experiment = ({ data }: ExperimentProps) => {
  const { featureFlag, layout } = readFragment(experimentFragment, data)
  const flagValue = useFeatureFlag(featureFlag?.key ?? undefined, false)

  if (!featureFlag || !featureFlag.key || featureFlag.value === null) {
    return null
  }

  const value = JSON.parse(featureFlag.value)

  if (flagValue !== value) {
    return null
  }

  return <Blocks blocks={layout} />
}
