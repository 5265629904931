import { useState } from 'react'

import { BillingTermsToggle } from '@/app/_components/BillingTermsToggle/index.jsx'
import { cmsBlockSectionFragment, BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { PricingCard } from '@/app/_components/PricingCard/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const priceTierFragment = graphql(
  /* GraphQL */ `
    fragment priceTierFragment on CmsPriceTier {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      tiers {
        tier {
          ... on CmsPricing {
            tierName
            tierDescription
            priceMonthly
            priceQuarterly
            priceYearly
            isPopular
            popularText
            includes {
              ... on CmsPricing {
                tierName
              }
            }
            buttonText
            buttonUrl
            features {
              text
              disclaimer
            }
          }
        }
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type PriceTierBlockProps = {
  data: FragmentOf<typeof priceTierFragment>
}

export const PriceTierBlock = ({ data }: PriceTierBlockProps) => {
  const [monthlyFrequency, setMonthlyFrequency] = useState(true)
  const { section, tiers, ctas, invertBackground } = readFragment(priceTierFragment, data)

  const allTiers = tiers?.map(({ tier }) => tier) ?? []

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="flex w-full flex-col justify-center gap-300">
          <BillingTermsToggle
            monthlySelected={monthlyFrequency}
            onMonthlySelected={() => setMonthlyFrequency(true)}
            onQuarterlySelected={() => setMonthlyFrequency(false)}
          />
          <ul className="flex w-full flex-col items-center justify-center gap-300 desktop:flex-row desktop:items-start">
            {tiers?.length
              ? tiers.map(({ tier }, index) => {
                  const title = tier?.tierName

                  if (!title) {
                    return null
                  }

                  const price = monthlyFrequency ? tier.priceMonthly : tier.priceQuarterly

                  const pricePeriod = monthlyFrequency ? 'month' : 'quarter'
                  const includedTiers = tier.includes?.map((i) => i.tierName)

                  const includedFeatures = allTiers
                    .filter((t) => t && includedTiers?.includes(t.tierName))
                    .map((t) =>
                      t?.features?.map((f) => (f.text as { children: { text: string }[] }[])[0]?.children[0]?.text),
                    )

                  return (
                    <li key={index} className="flex w-full max-w-[350px]">
                      <PricingCard
                        highlight={tier.isPopular ? tier.popularText : undefined}
                        title={title}
                        pricePeriod={pricePeriod}
                        price={`$${price}`}
                        priceCaption={tier.tierDescription}
                        features={tier.features?.map((f) => f.text) ?? []}
                        buttonLink={tier.buttonUrl}
                        buttonText={tier.buttonText}
                        includedFeatures={includedFeatures}
                      />
                    </li>
                  )
                })
              : []}
          </ul>
        </div>
      </Gutter>
    </BlockSection>
  )
}
