import * as Dialog from '@radix-ui/react-dialog'
import { ReactNode, useState } from 'react'
import classes from './filter-dialog.module.scss'
import { Button } from '@navinc/base-react-components/wayfinder'

interface FilterDialogProps {
  children: ReactNode
}

const CloseSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 13.4L7.1 18.3C6.91667 18.4833 6.68333 18.575 6.4 18.575C6.11667 18.575 5.88333 18.4833 5.7 18.3C5.51667 18.1167 5.425 17.8833 5.425 17.6C5.425 17.3167 5.51667 17.0833 5.7 16.9L10.6 12L5.7 7.1C5.51667 6.91667 5.425 6.68333 5.425 6.4C5.425 6.11667 5.51667 5.88333 5.7 5.7C5.88333 5.51667 6.11667 5.425 6.4 5.425C6.68333 5.425 6.91667 5.51667 7.1 5.7L12 10.6L16.9 5.7C17.0833 5.51667 17.3167 5.425 17.6 5.425C17.8833 5.425 18.1167 5.51667 18.3 5.7C18.4833 5.88333 18.575 6.11667 18.575 6.4C18.575 6.68333 18.4833 6.91667 18.3 7.1L13.4 12L18.3 16.9C18.4833 17.0833 18.575 17.3167 18.575 17.6C18.575 17.8833 18.4833 18.1167 18.3 18.3C18.1167 18.4833 17.8833 18.575 17.6 18.575C17.3167 18.575 17.0833 18.4833 16.9 18.3L12 13.4Z"
      fill="currentColor"
    />
  </svg>
)

export const FilterDialog = ({ children }: FilterDialogProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      className="FilterDialog relative z-[1000] flex items-center justify-between px-200 py-100"
      data-testid="marketplace:filter:dialog"
    >
      <div>
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Trigger asChild>
            <div className="flex justify-end w-full">
              <Button className="w-full justify-self-end">Filters</Button>
            </div>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className={`${classes.DialogOverlay} fixed inset-0 bg-inverseSurface md:hidden`} />
            <Dialog.Content
              className={`${classes.DialogContent} fixed left-1/2 top-1/2 z-[2000] h-screen  w-screen -translate-x-1/2 -translate-y-1/2 rounded-0 bg-surfaceBright focus:outline-none md:hidden`}
            >
              <div className="flex size-full flex-col overflow-scroll">
                <Dialog.Close asChild>
                  <button
                    className="absolute right-200 top-100 z-[2050] inline-flex size-600 items-center justify-center"
                    aria-label="Close"
                  >
                    <CloseSvg />
                  </button>
                </Dialog.Close>
                <div className="pt-1000">{children}</div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </div>
  )
}
