import { cn } from '@navinc/base-react-components'

import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'
import { StarRating } from '@/app/_components/StarRating/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { RichText } from '../../_components/RichText/index.js'
import { Children } from '../../_components/RichText/serialize.js'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import classes from './calltoActionBlock.module.scss'
import { CallToActionLinksGroup } from './CallToActionLinksGroup.js'

export const callToActionBlockFragment = graphql(
  /* GraphQL */ `
    fragment callToActionBlockFragment on CmsCTA {
      headline
      media {
        ...CmsMediaFragment
      }
      links {
        link {
          ...cmsLinkFragment
        }
      }
      ctaBannerType
      showReviews
      reviewText
      reviewRating
      productImage {
        ...CmsMediaFragment
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment],
)

export type CallToActionBlockProps = {
  data: FragmentOf<typeof callToActionBlockFragment>
  isNested?: boolean
}

export const CallToActionBlock = ({ data, isNested }: CallToActionBlockProps) => {
  const { headline, links, media, ctaBannerType, showReviews, reviewText, reviewRating, productImage } = readFragment(
    callToActionBlockFragment,
    data,
  )

  const type = ctaBannerType ?? 'default'

  return (
    <Gutter>
      <section
        className={cn(
          type === 'product' &&
            cn(classes.productCtaBanner, 'text-left text-onSurface md:px-[190px] pt-500 md:pt-1000'),
          type === 'default' &&
            cn(classes.defaultCtaBanner, 'text-center text-inverseOnSurface py-800 md:px-800 md:min-h-[450px]'),
          'relative flex items-center my-800 w-full bg-inverseSurface bg-cover bg-center px-200',
        )}
        style={{
          background:
            type === 'product'
              ? 'radial-gradient(100% 100% at 0% 0%, #D9D9D9 0%, #777680 100%)'
              : `url("${getMediaSizeUrlWithFallback(media, 'banner')}")`,
        }}
      >
        {type === 'default' && (
          <div className="pointer-events-none absolute inset-0 z-20 size-full bg-[rgba(0,0,0,0.2)]" />
        )}
        <div className={cn(type === 'product' && 'flex-col md:flex-row', 'flex w-full h-full relative z-30')}>
          <div
            className={cn(
              type === 'product' && 'justify-start pb-500 md:pb-1000 max-w-[530px]',
              type === 'default' && 'justify-center',
              'flex h-full w-full flex-col text-current',
            )}
          >
            <div className="flex flex-col gap-200 text-title2">
              <RichText content={headline as Children} />
            </div>
            {showReviews && reviewRating !== null && (
              <div className="mt-200">
                <StarRating rating={reviewRating} text={reviewText} />
              </div>
            )}
            {Array.isArray(links) && (
              <CallToActionLinksGroup
                links={links}
                isNested={isNested}
                ctaAlign={type === 'product' ? 'left' : 'center'}
              />
            )}
          </div>
          {type === 'product' && (
            <div className="relative h-[380px] w-[360px] self-end md:h-auto md:w-[354px]">
              <div className="absolute bottom-0 right-0">
                <img src={getMediaSizeUrlWithFallback(productImage, 'largeAutoHeight')} alt={productImage?.alt} />
              </div>
            </div>
          )}
        </div>
      </section>
    </Gutter>
  )
}
