import { OfferInfoBulletList } from './OfferBulletListItems.jsx'

export const OfferProsCons = ({ pros, cons }: { pros: string | string[]; cons: string | string[] }) => {
  return (
    <div className="grid grid-cols-2 gap-200 py-200 md:gap-400">
      {[
        { title: 'Pros', content: pros },
        { title: 'Cons', content: cons },
      ].map((list, index) => {
        return (
          <div key={index} className="col-span-2 md:col-span-1">
            <p className="caption1-emphasized mb-100 uppercase">{list.title}</p>
            <OfferInfoBulletList data={list.content} />
          </div>
        )
      })}
    </div>
  )
}
