import { ComparisionTableOfferValue, OfferComparisonTable } from './OfferComparisonTable.jsx'
import { MarketplaceProductOption, ProductOptionDetailCategory } from './queries/product-category-offers.query.js'
import { OfferCtaButton } from './shared/OfferCtaButton.jsx'
import { OffersListLayout } from './shared/OffersListLayout.jsx'
import { OfferSpecs } from './shared/OfferSpecs.jsx'
import { OverflowText } from './shared/OverflowText.jsx'
import { OfferFilter } from './shared/use-offer-filters.js'

export const ProductOffer = ({ offer }: { offer: MarketplaceProductOption }) => {
  if (!offer) {
    return null
  }

  return (
    <div>
      <p className="caption1 mb-100 py-50 font-semibold uppercase text-primary">{offer.tagline}</p>
      <div className=" rounded-200 border border-outlineVariant px-200 pt-200 shadow-elevation1">
        <div className="row1 grid grid-cols-12 gap-200">
          <div className="col-span-4 md:col-span-2">
            <div className="mx-auto flex max-w-[150px] flex-col items-center gap-200 py-200">
              <img src={offer.imageUrl} alt={`${offer.name} Logo`} />
              <OfferCtaButton url={offer.url}>Get started</OfferCtaButton>
            </div>
          </div>
          <div className="col-span-8 md:col-span-10">
            <div className="p-200">
              <p className="title3-emphasized mb-200">{offer.name}</p>
              <OverflowText>
                <p className="body2 whitespace-break-spaces">{offer.description}</p>
              </OverflowText>
            </div>
          </div>
        </div>

        {offer.details?.length ? (
          <OfferSpecs
            columns={offer.details.length}
            specs={offer.details.map((detail) => {
              if (!detail) {
                return null
              }

              return {
                title: detail.detailCategory.category,
                description:
                  detail.detail.length > 1 ? (
                    <ul className="caption1 ml-200 list-disc">
                      {detail.detail.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className="caption1">{detail.detail[0]}</p>
                  ),
              }
            })}
          />
        ) : null}
      </div>
    </div>
  )
}

export const ProductOffers = ({
  offers,
  filters,
  detailCategories,
}: {
  offers: MarketplaceProductOption[]
  filters: OfferFilter[]
  detailCategories: (ProductOptionDetailCategory | null)[] | null
}) => {
  return (
    <>
      <OffersListLayout
        offers={offers}
        filters={filters}
        renderOffer={({ offer }) => <ProductOffer offer={offer as MarketplaceProductOption} />}
      />
      <OfferComparisonTable
        offers={offers.map((offer) => {
          return {
            title: (
              <div className="flex flex-col gap-100">
                <div className="h-auto max-w-[100px]">
                  <img src={offer.imageUrl} alt={`${offer.name} Logo`} />
                </div>{' '}
                <strong className="font-semibold">{offer.name}</strong>
              </div>
            ),
            ...(detailCategories ?? []).reduce(
              (final, detailCategory) => {
                if (detailCategory) {
                  final[detailCategory.category] = offer.details?.find(
                    (detail) => detail?.detailCategory.category === detailCategory.category,
                  )?.detail
                }
                return final
              },
              {} as { [key: string]: ComparisionTableOfferValue },
            ),
          }
        })}
        columns={[
          {
            key: 'title',
            label: 'Compare your options',
          },
        ].concat(
          (detailCategories ?? [])
            .map((detailCategory) => {
              if (!detailCategory) {
                return null
              }
              return {
                key: detailCategory.category,
                label: detailCategory.category,
              }
            })
            .filter((d) => d !== null),
        )}
      />
    </>
  )
}
