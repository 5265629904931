import { cn } from '@navinc/base-react-components'
import { CSSProperties } from 'styled-components'

export const OfferInfoBulletList = ({
  data,
  className,
  style = {},
}: {
  data: string | null | (string | null)[]
  className?: string
  style?: CSSProperties
}) => {
  if (!data) {
    return null
  }
  let listItems: Array<string> = []
  if (typeof data === 'string') {
    // split at periods to show in a list
    listItems = data.split('. ')
  } else if (Array.isArray(data)) {
    listItems = data.filter((d) => d !== null)
  }

  if (!listItems.length) {
    return null
  }

  return (
    <ul className={cn('body2 ml-300 list-disc', className)} style={style}>
      {listItems.map((listItem, index) => {
        return (
          <li className="mb-100" key={index}>
            {listItem}
          </li>
        )
      })}
    </ul>
  )
}
