import { RefObject, useEffect, useRef, useState } from 'react'

import { useActiveNavigationScroll } from './use-active-navigation-scroll.js'
import { cn } from '@navinc/base-react-components'
const slug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[\s\W]+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const TableOfContents = ({
  headings,
  sectionRefs,
}: {
  headings?: {
    text: string
  }[]
  sectionRefs: { [key: string]: RefObject<HTMLDivElement> }
  title?: string
}) => {
  const navRef = useRef<HTMLLIElement>(null)
  const [currentWidth, setCurrentWidth] = useState(0)

  const isMobile = currentWidth <= 1280
  const { isSelected, handleClickedActiveSection } = useActiveNavigationScroll({
    sectionRefs,
    navigationRef: navRef,
  })

  useEffect(() => {
    setCurrentWidth(window.innerWidth)
    const handleResize = () => {
      setCurrentWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isMobile) return null

  return (
    <div className="accordion sticky top-[70px]">
      <ol className="flex flex-col border border-outlineVariant rounded-200 mt-400">
        {headings?.map((heading, index) => (
          <li
            key={index}
            ref={navRef}
            className="list-decimal flex h-full border-t border-outlineVariant first:border-0"
          >
            <button
              className={cn(
                'p-200 headline font-semibold pl-200 text-start flex gap-200',
                isSelected(slug(heading.text)) && 'text-primary',
              )}
              onClick={() => handleClickedActiveSection(slug(heading.text), sectionRefs[slug(heading.text)])}
            >
              <span>{index + 1}</span>
              {heading.text}
              <span />
            </button>
          </li>
        ))}
      </ol>
    </div>
  )
}
