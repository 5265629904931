import { cmsBlockSectionFragment, BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { RichText } from '@/app/_components/RichText/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { QuoteCard } from '../../_components/QuoteCard/index.js'
import { Children } from '../../_components/RichText/serialize.js'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const quoteFragment = graphql(
  /* GraphQL */ `
    fragment quoteFragment on CmsQuote {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      quoteStyle
      quotes {
        author
        business
        quoteText
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type QuotesProps = {
  data: FragmentOf<typeof quoteFragment>
}

type Quote = {
  quoteText: unknown | Children | null
  author: string | null
  business: string | null
}

const QuoteCards = ({ quotes }: { quotes: Quote[] | null }) => {
  return (
    <div className="flex flex-col items-start justify-center gap-300 md:flex-row md:justify-evenly md:gap-500">
      {quotes?.map((quote, index) => {
        return (
          <div key={index} className="mx-auto flex w-full justify-center">
            <QuoteCard quote={quote.quoteText} author={quote.author} company={quote.business} />
          </div>
        )
      })}
    </div>
  )
}

const BlockQuotes = ({ quotes }: { quotes: Quote[] | null }) => {
  return (
    <div className="flex w-full flex-col gap-200">
      {quotes?.map((quote, index) => {
        return (
          <div
            key={index}
            className="title1 mx-auto flex w-full justify-center bg-[#D9D6FF] px-300 py-600 text-center text-onPrime md:px-400 md:py-[128px]"
          >
            <div className="max-w-[940px]">
              <RichText content={quote.quoteText as Children} />
              <p className="title3 mt-200">&mdash; {quote.author}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export const Quotes = ({ data }: QuotesProps) => {
  const { section, quotes, ctas, invertBackground, quoteStyle } = readFragment(quoteFragment, data)

  const variant = quoteStyle ?? 'card'
  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />

        {variant === 'card' && <QuoteCards quotes={quotes} />}
        {variant === 'blockquote' && <BlockQuotes quotes={quotes} />}
      </Gutter>
    </BlockSection>
  )
}
