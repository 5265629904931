import { type RefObject, useCallback, useEffect, useRef, useState } from 'react'

type Params = {
  sectionRefs: Record<string, RefObject<HTMLDivElement>>
  navigationRef: RefObject<HTMLLIElement>
}

/**
 * Custom hook for managing active section highlighting and sticky navigation behavior.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {Record<string, RefObject<HTMLDivElement>>} params.sectionRefs - An object mapping section IDs to their corresponding refs.
 * @param {RefObject<HTMLDivElement>} params.navigationRef - A ref to the navigation header element.
 *
 * @returns {Object} - The hook returns an object with the following properties:
 * @returns {boolean} isSticky - Indicates if the navigation header is currently sticky.
 * @returns {function(string): boolean} isSelected - A function that checks if a given section ID is currently active.
 * @returns {function(string, RefObject<HTMLDivElement>): void} handleClickedActiveSection - A function to handle clicking on a section and scroll to it.
 */

export const useActiveNavigationScroll = ({ sectionRefs, navigationRef }: Params) => {
  const [isSticky, setIsSticky] = useState(false)
  const [activeSectionId, setActiveSectionId] = useState('')

  const isClicked = useRef(false)
  const timeout = useRef<NodeJS.Timeout>()

  const scrollToSection = (elementRef: RefObject<HTMLDivElement>) => {
    if (!elementRef.current) return

    window.scrollTo({
      top: navigationRef.current
        ? elementRef.current.offsetTop - navigationRef.current.offsetHeight - 24
        : elementRef.current.offsetTop,
      behavior: 'smooth',
    })
    // This timeout prevents weird flickering issues when clicking to scroll to section
    timeout.current = setTimeout(() => {
      isClicked.current = false
    }, 800)
  }

  const handlePageSections = useCallback(
    (stickyHeader?: RefObject<HTMLLIElement>) => {
      const sections = Object.values(sectionRefs)
      const pageY = window.scrollY

      let headerHeight: number

      if (stickyHeader?.current) {
        const headerPadding = 10
        headerHeight = stickyHeader.current.offsetHeight + headerPadding
      }

      let newActiveSection = ''

      sections.forEach((section) => {
        if (section.current) {
          // accounting for the possibility of not having a sticky header
          const sectionOffsetTop = headerHeight ? section.current.offsetTop - headerHeight : section.current.offsetTop

          const sectionHeight = section.current.offsetHeight

          if (pageY >= sectionOffsetTop && pageY < sectionOffsetTop + sectionHeight) {
            newActiveSection = section.current.id || ''
          }
        }
      })
      setActiveSectionId(newActiveSection)
    },
    [sectionRefs],
  )

  useEffect(() => {
    const handleScroll = () => {
      if (!isClicked.current) {
        handlePageSections(navigationRef)
      }
      if (navigationRef.current && Math.ceil(window.scrollY) === navigationRef.current.offsetTop) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handlePageSections, navigationRef])

  const isSelected = (id: string) => activeSectionId === id

  const handleClickedActiveSection = (id: string, ref: RefObject<HTMLDivElement> | undefined) => {
    clearTimeout(timeout.current)

    isClicked.current = true
    setActiveSectionId(id)
    if (ref) {
      scrollToSection(ref)
    }
  }

  return {
    isSticky,
    isSelected,
    handleClickedActiveSection,
  }
}
