import { BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { CmsMediaFragment } from '@/app/_graphql/media.js'
import { Gutter } from '@/app/_components/Gutter/index.jsx'
import { RichText } from '@/app/_components/RichText/index.jsx'
import { Children } from '@/app/_components/RichText/serialize.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'

export const downloadBannerFragment = graphql(
  /* GraphQL */ `
    fragment downloadBannerFragment on CmsDownloadBanner {
      invertBackground
      richText
      media {
        ...CmsMediaFragment
      }
    }
  `,
  [CmsMediaFragment],
)

export type DownloadBannerProps = {
  data: FragmentOf<typeof downloadBannerFragment>
  isNested?: boolean
}

export const DownloadBanner = ({ data, isNested }: DownloadBannerProps) => {
  const { invertBackground, richText, media } = readFragment(downloadBannerFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested}>
      <Gutter>
        <div className="flex flex-col md:flex-row gap-400 md:justify-center md:items-center">
          <img
            src={getMediaSizeUrlWithFallback(media, 'largeAutoHeight')}
            alt={media?.alt}
            className="max-w-[128px] object-cover pb-250"
          />
          <div className="flex flex-col">
            <RichText content={richText as Children} />
          </div>
        </div>
      </Gutter>
    </BlockSection>
  )
}
