import { cn } from '@navinc/base-react-components'

import { FragmentOf, readFragment } from '../../lib/singer.js'
import { CMSLinkFromFragment, LinkFragment } from '../Link/index.jsx'
import { RichText } from '../RichText/index.jsx'
import { Children } from '../RichText/serialize.jsx'
import { graphql } from '@/app/lib/cms.js'

export const cmsSectionFragment = graphql(/* GraphQL */ `
  fragment cmsSectionFragment on CmsSection {
    heading
    ctaAlign
    headingMax
  }
`)

export const SectionHeader = ({
  links,
  className,
  textAlignClassName,
  data,
}: {
  links?: { link: LinkFragment | null }[] | null
  className?: string
  textAlignClassName?: string
  data: FragmentOf<typeof cmsSectionFragment> | null
}) => {
  if (!data) {
    return null
  }
  const { heading, ctaAlign, headingMax } = readFragment(cmsSectionFragment, data)
  // Tailwind classes for alignment based on `align` prop
  const alignmentClasses = {
    center: 'justify-center',
    left: 'justify-start',
    right: 'justify-end',
  }[ctaAlign ?? 'center']

  return (
    <div
      className={cn(
        className,
        textAlignClassName ?? 'text-center',
        `flex flex-col gap-400 self-stretch mb-400 mx-auto`,
      )}
      style={{
        maxWidth: headingMax ? `${headingMax}px` : 'none',
      }}
    >
      <div className="flex flex-col items-center gap-200 text-inherit">
        <RichText content={heading as Children} />
      </div>
      {links && links.length ? (
        <ul className={cn(`m-0 flex list-none gap-200 p-0 w-full md:w-auto`, alignmentClasses)}>
          {links.map(({ link }, i) => {
            if (!link) return null

            return (
              <li key={i} className="w-[inherit]">
                <CMSLinkFromFragment data={link} density="standard" className="w-[inherit]" />
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  )
}
