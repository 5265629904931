import { cmsBlockSectionFragment, BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { cmsDisclaimerFragment, Disclaimer } from '@/app/_components/Disclaimer/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const disclaimersFragment = graphql(
  /* GraphQL */ `
    fragment disclaimersFragment on CmsDisclaimers {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      disclaimers {
        ...cmsDisclaimerFragment
      }
    }
  `,
  [cmsLinkFragment, cmsDisclaimerFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type DisclaimersProps = {
  data: FragmentOf<typeof disclaimersFragment>
}

export const Disclaimers = ({ data }: DisclaimersProps) => {
  const { section, disclaimers, ctas, invertBackground } = readFragment(disclaimersFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} className="pt-0 md:pt-0" section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="flex flex-col gap-100">
          {disclaimers?.map((disclaimer, index) => {
            return <Disclaimer key={index} {...disclaimer} />
          })}
        </div>
      </Gutter>
    </BlockSection>
  )
}
