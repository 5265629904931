import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { Carousel } from '@/app/_components/Carousel/index.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const carouselFragment = graphql(
  /* GraphQL */ `
    fragment carouselFragment on CmsCarousel {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }

      slides {
        media {
          ...CmsMediaFragment
        }
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type CarouselBlockProps = {
  data: FragmentOf<typeof carouselFragment>
}

export const CarouselBlock = ({ data }: CarouselBlockProps) => {
  const { section, slides, ctas, invertBackground } = readFragment(carouselFragment, data)

  const slideImages = slides?.length
    ? slides.map((slide, index) => {
        if (!slide.media) return null

        return (
          <div key={index} className="">
            <img
              className="h-[350px] w-[400px] rounded-200 object-cover"
              src={getMediaSizeUrlWithFallback(slide.media, 'squareThumbnail')}
              alt={slide.media.alt}
            />
          </div>
        )
      })
    : []
  return (
    <BlockSection invertBackground={invertBackground} className="px-0" section={section}>
      <Gutter>
        <div className="px-200">
          <SectionHeader data={section} links={ctas} />
        </div>
        <div className="m-auto">
          <Carousel slides={slideImages} />
        </div>
      </Gutter>
    </BlockSection>
  )
}
