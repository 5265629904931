import { RichText } from '../RichText/index.js'

type QuoteCardProps = {
  author: string | null
  company: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- fix later
  quote: any // Leaf[] | string
  starRating?: number
}

const StarsIcon = () => {
  return (
    <svg width="128" height="21" viewBox="0 0 128 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66649 0.927051C9.96584 0.00574017 11.2692 0.0057404 11.5686 0.927051L13.3716 6.4762C13.5055 6.88822 13.8895 7.16718 14.3227 7.16718H20.1574C21.1261 7.16718 21.5289 8.4068 20.7452 8.9762L16.0248 12.4058C15.6743 12.6604 15.5277 13.1118 15.6615 13.5238L17.4646 19.073C17.7639 19.9943 16.7094 20.7604 15.9257 20.191L11.2053 16.7614C10.8548 16.5068 10.3802 16.5068 10.0298 16.7614L5.30937 20.191C4.52566 20.7604 3.47118 19.9943 3.77053 19.0729L5.57356 13.5238C5.70743 13.1118 5.56077 12.6604 5.21029 12.4058L0.489897 8.9762C-0.293816 8.4068 0.108959 7.16718 1.07768 7.16718H6.9124C7.34563 7.16718 7.72959 6.88822 7.86346 6.4762L9.66649 0.927051Z"
        fill="#5E4DB2"
      />
      <path
        d="M36.2002 0.927051C36.4996 0.00574017 37.803 0.0057404 38.1023 0.927051L39.9054 6.4762C40.0392 6.88822 40.4232 7.16718 40.8564 7.16718H46.6912C47.6599 7.16718 48.0627 8.4068 47.2789 8.9762L42.5586 12.4058C42.2081 12.6604 42.0614 13.1118 42.1953 13.5238L43.9983 19.0729C44.2977 19.9943 43.2432 20.7604 42.4595 20.191L37.7391 16.7614C37.3886 16.5068 36.914 16.5068 36.5635 16.7614L31.8431 20.191C31.0594 20.7604 30.0049 19.9943 30.3043 19.0729L32.1073 13.5238C32.2412 13.1118 32.0945 12.6604 31.744 12.4058L27.0236 8.9762C26.2399 8.4068 26.6427 7.16718 27.6114 7.16718H33.4462C33.8794 7.16718 34.2633 6.88822 34.3972 6.4762L36.2002 0.927051Z"
        fill="#5E4DB2"
      />
      <path
        d="M62.734 0.927051C63.0333 0.0057404 64.3367 0.00574017 64.6361 0.927051L66.4391 6.4762C66.573 6.88822 66.957 7.16718 67.3902 7.16718H73.2249C74.1936 7.16718 74.5964 8.4068 73.8127 8.9762L69.0923 12.4058C68.7418 12.6604 68.5951 13.1118 68.729 13.5238L70.5321 19.0729C70.8314 19.9943 69.7769 20.7604 68.9932 20.191L64.2728 16.7614C63.9223 16.5068 63.4477 16.5068 63.0973 16.7614L58.3769 20.191C57.5932 20.7604 56.5387 19.9943 56.838 19.0729L58.641 13.5238C58.7749 13.1118 58.6283 12.6604 58.2778 12.4058L53.5574 8.9762C52.7737 8.4068 53.1765 7.16718 54.1452 7.16718H59.9799C60.4131 7.16718 60.7971 6.88822 60.931 6.4762L62.734 0.927051Z"
        fill="#5E4DB2"
      />
      <path
        d="M89.2677 0.927051C89.5671 0.0057404 90.8705 0.00574017 91.1698 0.927051L92.9729 6.4762C93.1067 6.88822 93.4907 7.16718 93.9239 7.16718H99.7586C100.727 7.16718 101.13 8.4068 100.346 8.9762L95.626 12.4058C95.2756 12.6604 95.1289 13.1118 95.2628 13.5238L97.0658 19.0729C97.3652 19.9943 96.3107 20.7604 95.527 20.191L90.8066 16.7614C90.4561 16.5068 89.9815 16.5068 89.631 16.7614L84.9106 20.191C84.1269 20.7604 83.0724 19.9943 83.3718 19.073L85.1748 13.5238C85.3087 13.1118 85.162 12.6604 84.8115 12.4058L80.0911 8.9762C79.3074 8.4068 79.7102 7.16718 80.6789 7.16718H86.5136C86.9469 7.16718 87.3308 6.88822 87.4647 6.4762L89.2677 0.927051Z"
        fill="#5E4DB2"
      />
      <path
        d="M115.801 0.927051C116.101 0.0057404 117.404 0.00574017 117.704 0.927051L119.507 6.4762C119.64 6.88822 120.024 7.16718 120.458 7.16718H126.292C127.261 7.16718 127.664 8.4068 126.88 8.9762L122.16 12.4058C121.809 12.6604 121.663 13.1118 121.797 13.5238L123.6 19.0729C123.899 19.9943 122.844 20.7604 122.061 20.191L117.34 16.7614C116.99 16.5068 116.515 16.5068 116.165 16.7614L111.444 20.191C110.661 20.7604 109.606 19.9943 109.906 19.073L111.709 13.5238C111.842 13.1118 111.696 12.6604 111.345 12.4058L106.625 8.9762C105.841 8.4068 106.244 7.16718 107.213 7.16718H113.047C113.481 7.16718 113.865 6.88822 113.998 6.4762L115.801 0.927051Z"
        fill="#5E4DB2"
      />
    </svg>
  )
}

export const QuoteCard = ({ author, company, quote }: QuoteCardProps) => {
  return (
    <div className="title2 flex max-w-[700px] flex-1 flex-col gap-300 rounded-200 bg-surfaceBright p-300 text-onSurface shadow-elevation1">
      <div className="flex">
        <StarsIcon />
      </div>
      {typeof quote === 'string' ? (
        <p className="title2">{quote}</p> // Adjusted size based on Tailwind text size classes
      ) : (
        <RichText content={quote} />
      )}
      <div className="flex flex-col gap-50">
        <p className="body1 font-semibold">{author}</p> {/* Adjusted size and variant based on Tailwind text classes */}
        <p className="caption1-uppercase text-primary">{company}</p> {/* Adjusted size, variant, and color */}
      </div>
    </div>
  )
}
