import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { Accordion, accordionFragment } from '../Accordion/index.jsx'
import { LoaderBlocksData } from '../Blocks.jsx'
import { CallToActionBlock, callToActionBlockFragment } from '../CallToAction/index.jsx'
import { ContentBlock, contentFragment } from '../Content/index.jsx'
import { ImageBlock, imageBlockFragment } from '../ImageBlock/index.jsx'
import { OfferBlock, offerBlockFragment } from '../OfferBlock/index.jsx'
import { TableBlock, tableBlockFragment } from '../TableBlock/index.jsx'
import { VideoBlock, videoBlockFragment } from '../VideoBlock/index.jsx'

export const richTextNestedBlocksFragment = graphql(
  /* GraphQL */ `
    fragment richTextNestedBlocksFragment on CmsRichTextNestedBlocks {
      layout {
        __typename
        ...callToActionBlockFragment
        ...contentFragment
        ...accordionFragment
        ...videoBlockFragment
        ...offerBlockFragment
        ...tableBlockFragment
        ...imageBlockFragment
      }
    }
  `,
  [
    callToActionBlockFragment,
    contentFragment,
    accordionFragment,
    videoBlockFragment,
    offerBlockFragment,
    tableBlockFragment,
    imageBlockFragment,
  ],
)

const allBlocks = {
  CmsCTA: CallToActionBlock,
  CmsContent: ContentBlock,
  CmsAccordion: Accordion,
  CmsVideoBlock: VideoBlock,
  CmsOfferBlock: OfferBlock,
  CmsTableBlock: TableBlock,
  CmsImageBlock: ImageBlock,
}

type BlocksProps = {
  data: FragmentOf<typeof richTextNestedBlocksFragment> | null
  loaderBlocksData?: LoaderBlocksData
}

type AllBlockData = { __typename: keyof typeof allBlocks }

const BlockComponent = (props: { blocks: AllBlockData[] | null; loaderBlocksData?: LoaderBlocksData }) => {
  const { blocks, loaderBlocksData } = props

  if (!blocks || !Array.isArray(blocks) || blocks.length === 0) {
    return null
  }

  return (
    <>
      {blocks.map((block, index) => {
        const blockType = block.__typename

        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
        const Block = allBlocks[blockType] as any
        if (Block) {
          return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
            <Block key={`${blockType}-${index}`} data={block as any} loaderBlocksData={loaderBlocksData} isNested />
          )
        }
        return null
      })}
    </>
  )
}

export const RichTextNestedBlocks = ({ data, loaderBlocksData }: BlocksProps) => {
  if (!data) {
    return null
  }

  const { layout } = readFragment(richTextNestedBlocksFragment, data)

  return <BlockComponent blocks={layout} loaderBlocksData={loaderBlocksData} />
}
