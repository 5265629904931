import { Button } from '@navinc/base-react-components/wayfinder'
import { ReactNode, useState } from 'react'

type OfferDrawerProps = {
  children: ReactNode
  defaultOpen?: boolean
}

export const OfferDrawer = ({ children, defaultOpen }: OfferDrawerProps) => {
  const [detailsVisible, setDetailsVisible] = useState(!!defaultOpen)

  return (
    <>
      {detailsVisible && <div>{children}</div>}
      <Button className="w-full p-150" variant="plain" onClick={() => setDetailsVisible((visible) => !visible)}>
        {detailsVisible ? 'Hide details' : 'Show details'}
      </Button>
    </>
  )
}
