import { ReactNode } from 'react'

import { OfferPaginationControls } from './shared/OfferPaginationControls.jsx'
import { OfferResults } from './shared/OfferResults.jsx'
import { useShowMoreLess } from './shared/use-show-more-less.js'

export type ComparisionTableOfferValue = string[] | string | ReactNode

export type OfferComparisonTableProps = {
  offers: { [key: string]: ComparisionTableOfferValue }[]
  columns: { label: string; key: string }[]
}

export const OfferComparisonTable = ({ offers, columns }: OfferComparisonTableProps) => {
  const initialItemsToShow = 6
  const { currentItems, showMoreItems, showLessItems, hasMoreItems, hasLessItems } = useShowMoreLess(
    offers,
    initialItemsToShow,
  )

  const offerResults = <OfferResults visibleCount={currentItems.length} offersListCount={offers.length} />

  return (
    <div id="compare" className="mx-auto flex flex-col gap-300 py-400">
      <h2 className="display2-emphasized text-center">Compare your options</h2>
      <div className="w-full overflow-x-scroll">
        {offerResults}
        <table className="mt-200 min-w-full rounded-200 border border-outlineVariant bg-surfaceBright px-200 pt-200 shadow-elevation1">
          <thead>
            <tr className="border-b border-outlineVariant bg-surfaceContainerLow">
              {columns.map((column, thColumnIndex) => {
                return (
                  <th key={thColumnIndex} className="px-300 py-200 text-left">
                    {column.label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((offer, index) => (
              <tr key={index} className="border-b border-outlineVariant last:border-0">
                {columns.map((column, tdColumnIndex) => {
                  const offerValue = offer[column.key]
                  const tdClassName = 'py-200 px-300 body2 text-onSurfaceVariant align-top'

                  if (Array.isArray(offerValue)) {
                    if (offerValue.length === 1) {
                      return offerValue.map((value, idx) => (
                        <td key={idx} className={tdClassName}>
                          {value}
                        </td>
                      ))
                    }
                    return (
                      <td key={tdColumnIndex} className={tdClassName}>
                        <ul className="ml-300 list-disc">
                          {offerValue.map((value, idx) => (
                            <li key={idx}>{value}</li>
                          ))}
                        </ul>
                      </td>
                    )
                  }

                  return (
                    <td key={tdColumnIndex} className={tdClassName}>
                      {offerValue}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex items-center gap-200 py-200">
          {offerResults}
          <OfferPaginationControls
            hasMoreItems={hasMoreItems}
            showMoreItems={showMoreItems}
            hasLessItems={hasLessItems}
            showLessItems={showLessItems}
          />
        </div>
      </div>
    </div>
  )
}
