import { Button } from '@navinc/base-react-components/wayfinder'

import { SmartLink } from '../../SmartLink/SmartLink.jsx'

export type OfferCtaButtonProps = { url: string; children: string }

export const OfferCtaButton = ({ url, children }: OfferCtaButtonProps) => {
  return (
    <Button variant="primary" density="standard" asChild>
      <SmartLink to={url} target="_blank" rel="noreferrer">
        {children}
      </SmartLink>
    </Button>
  )
}
