import { Checkbox } from '@navinc/base-react-components/wayfinder'
import { Button } from '@navinc/base-react-components/wayfinder'

import { OfferFilter, useOfferFilters } from './use-offer-filters.js'

export const OfferFilters = ({ filters }: { filters: OfferFilter[] }) => {
  const { handleClearFilters, handleFilterChange, getFilterValueByName } = useOfferFilters({ filters })
  return (
    <div className="px-200 md:p-0">
      <p className="headline flex justify-between font-semibold">
        Filter Results{' '}
        <Button variant="default" onClick={handleClearFilters}>
          Clear all filters
        </Button>
      </p>
      {filters.map((filter, filterIndex) => {
        if (!filter) {
          return null
        }
        const filterName = `filter.${filter.name}`
        return (
          <div key={filterIndex} className="border-b border-outlineVariant py-150 last:border-b-0">
            <p className="body2 mb-100">{filter.name}</p>
            <ul className="flex flex-col gap-100">
              {filter.options?.map((option, optionIndex) => {
                if (!option) {
                  return null
                }
                return (
                  <li key={optionIndex} className="body1 flex items-center">
                    <Checkbox
                      checked={getFilterValueByName(filterName).includes(option)}
                      onChange={(e) => {
                        handleFilterChange(filterName, option, e.target.checked)
                      }}
                      label={option}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}
