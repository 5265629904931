import { FragmentOf } from 'gql.tada'

import { cmsLinkFragment, CMSLinkFromFragment } from '../../_components/Link/index.js'
import { cn } from '@navinc/base-react-components'

export type HeroLinksGroupProps = {
  links: { link: FragmentOf<typeof cmsLinkFragment> | null }[] | null
  horizontalAlign?: 'left' | 'right' | 'center' | null
}
export const HeroLinksGroup = ({ links, horizontalAlign }: HeroLinksGroupProps) => {
  const horizontalAlignClass = {
    left: 'justify-start',
    right: 'justify-end',
    center: 'justify-center',
  }[horizontalAlign ?? 'left']

  return (
    <div
      className={cn('relative z-30 mt-300 flex list-none justify-start gap-200 p-0 md:mt-400', horizontalAlignClass)}
    >
      {links?.map(({ link }, i) => {
        if (!link) return null

        return (
          <CMSLinkFromFragment
            key={i}
            data={link}
            density="loose"
            className="w-full md:w-auto"
            data-testid={`hero:cta-button-${i}`}
          />
        )
      })}
    </div>
  )
}
