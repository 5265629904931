import { Badge, Divider, Icon } from '@navinc/base-react-components/wayfinder'
import { toKebabCase } from '@navinc/utils'
import { Fragment } from 'react'

import { CMSLink } from '../Link/index.jsx'
import { RichText } from '../RichText/index.jsx'
import { Children } from '../RichText/serialize.jsx'

const dataTestIdPrefix = 'pricing-card:'

interface PricingCardProps {
  title: string
  price: string
  pricePeriod: string
  priceCaption: string
  buttonText: string
  buttonLink: string
  features: unknown[] | string[]
  highlight?: string | null
  includedFeatures?: unknown[] | string[]
}

const FeatureItem = ({ feature, dataTestid }: { feature: string | Children; dataTestid: string }) => (
  <li className="body1 flex text-onSurface" data-testid={dataTestid}>
    <div className="flex items-center gap-75">
      <Icon name="check" size="medium" />
      {typeof feature === 'string' ? (
        <p className="text-body1">{feature}</p>
      ) : (
        <RichText content={feature as Children} />
      )}
    </div>
  </li>
)

export const PricingCard = (props: PricingCardProps) => {
  const highlightBorder = props.highlight
    ? 'border border-solid border-outlineVariant'
    : 'border-2 border-solid border-outlineVariant'

  const tierName = toKebabCase(props.title)

  return (
    <div
      className={`${highlightBorder} mx-auto flex h-fit max-w-[400px] flex-1 flex-col items-start  rounded-300 bg-surface px-200 shadow-elevation1`}
      data-testid={`${dataTestIdPrefix}${tierName}`}
    >
      <div className="w-full">
        <div className="Inner flex flex-col gap-200 py-200">
          <div className="flex justify-between">
            <p className="title3-emphasized text-onSurface">{props.title}</p>
            {props.highlight && <Badge variant="prime">{props.highlight}</Badge>}
          </div>
          <div className="flex items-end gap-75">
            <p className="title1 text-onSurface" data-testid={`${dataTestIdPrefix}${tierName}-price`}>
              {props.price}
            </p>
            <p className="body2 text-onSurface">/ mo{props.pricePeriod === 'quarter' ? ', billed quarterly' : ''}</p>
          </div>
          <p className="body1 text-onSurface">{props.priceCaption}</p>
          <CMSLink
            variant={props.highlight ? 'prime' : 'default'}
            url={props.buttonLink}
            type="custom"
            data-testid={`${dataTestIdPrefix}:${tierName}-button`}
          >
            {props.buttonText}
          </CMSLink>
        </div>
      </div>
      <div className="flex w-full flex-col gap-100 p-200">
        {(props.includedFeatures ?? []).length > 0 &&
          (props.includedFeatures ?? []).map((featureTier, tierIndex) => (
            <Fragment key={tierIndex}>
              <ul className="flex flex-col gap-200">
                {Array.isArray(featureTier) &&
                  featureTier.map((feature, index) => (
                    <FeatureItem
                      key={index}
                      feature={feature}
                      dataTestid={`${dataTestIdPrefix}${tierName}-included-features-${tierIndex}-${index}`}
                    />
                  ))}
              </ul>
              <Divider className="my-100" />
            </Fragment>
          ))}
        <ul className="flex flex-col gap-100">
          {props.features.map((feature, index) => (
            <FeatureItem
              key={index}
              feature={feature as string}
              dataTestid={`${dataTestIdPrefix}${tierName}-feature-${index}`}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
