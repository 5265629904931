import { FragmentOf, graphql, readFragment } from '../lib/cms.js'
import { baseBlocksFragments, baseBlocksFragmentString } from './base-blocks.js'
import { createExtendedBlocks } from './Experiment/blocks.jsx'
import { Experiment, experimentFragment } from './Experiment/index.jsx'

export const blocksWithExperimentFragment = graphql(
  /* GraphQL */ `
    fragment blocksWithExperimentFragment on CmsContentLayout {
      layout {
        __typename
        ${baseBlocksFragmentString}
        ...experimentFragment
      }
    }
  `,
  [...baseBlocksFragments, experimentFragment],
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
export type LoaderBlocksData = { [key: string]: any }

const BlocksComponent = createExtendedBlocks({
  CmsExperiment: Experiment,
})

type BlocksProps = {
  data: FragmentOf<typeof blocksWithExperimentFragment> | null
  loaderBlocksData?: LoaderBlocksData
}

export const Blocks = ({ data, loaderBlocksData }: BlocksProps) => {
  if (!data) {
    return null
  }

  const { layout } = readFragment(blocksWithExperimentFragment, data)

  return <BlocksComponent blocks={layout} loaderBlocksData={loaderBlocksData} />
}
