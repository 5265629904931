import { cn } from '@navinc/base-react-components'

import { BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'

import { Feature } from '../../_components/Feature/index.js'
import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const featureGridFragment = graphql(
  /* GraphQL */ `
    fragment featureGridFragment on CmsFeatureGrid {
      invertBackground
      section {
        ...cmsSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      features {
        title
        description
        iconName
        fillIcon
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment],
)

export type FeaturesGridBlockProps = {
  data: FragmentOf<typeof featureGridFragment>
}

export const FeatureGridBlock = ({ data }: FeaturesGridBlockProps) => {
  const { section, features, ctas, invertBackground } = readFragment(featureGridFragment, data)
  const featureCount = features?.length

  const desktopClasses = 'justify-evenly md:flex hidden gap-200'
  const mobileClasses = 'justify-evenly md:hidden flex gap-200'

  return (
    <BlockSection invertBackground={invertBackground}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <div className="flex flex-col gap-200">
          <div className={cn(desktopClasses, invertBackground ? 'text-inverseOnSurface' : 'text-onSurface')}>
            {features?.slice(0, 3).map((feature, index) => {
              return (
                <Feature
                  key={index}
                  iconName={feature.iconName!}
                  title={feature.title!}
                  description={feature.description!}
                  fillIcon={typeof feature.fillIcon === 'boolean' && feature.fillIcon}
                />
              )
            })}
          </div>
          {featureCount && featureCount > 3 && (
            <div className={cn(desktopClasses, invertBackground ? 'text-inverseOnSurface' : 'text-onSurface')}>
              {features.slice(3, 6).map((feature, index) => {
                return (
                  <Feature
                    key={index}
                    iconName={feature.iconName!}
                    title={feature.title!}
                    description={feature.description!}
                    fillIcon={typeof feature.fillIcon === 'boolean' && feature.fillIcon}
                  />
                )
              })}
            </div>
          )}
          {/* mobile below */}

          <div className={cn(mobileClasses, invertBackground ? 'text-inverseOnSurface' : 'text-onSurface')}>
            {features?.slice(0, 2).map((feature, index) => {
              return (
                <Feature
                  key={index}
                  iconName={feature.iconName!}
                  title={feature.title!}
                  description={feature.description!}
                  fillIcon={typeof feature.fillIcon === 'boolean' && feature.fillIcon}
                />
              )
            })}
          </div>
          {featureCount && featureCount > 2 && (
            <div className={cn(mobileClasses, invertBackground ? 'text-inverseOnSurface' : 'text-onSurface')}>
              {features.slice(2, 4).map((feature, index) => {
                return (
                  <Feature
                    key={index}
                    iconName={feature.iconName!}
                    title={feature.title!}
                    description={feature.description!}
                    fillIcon={typeof feature.fillIcon === 'boolean' && feature.fillIcon}
                  />
                )
              })}
            </div>
          )}
          {featureCount && featureCount > 4 && (
            <div className={cn(mobileClasses, invertBackground ? 'text-inverseOnSurface' : 'text-onSurface')}>
              {features.slice(4, 6).map((feature, index) => {
                return (
                  <Feature
                    key={index}
                    iconName={feature.iconName!}
                    title={feature.title!}
                    description={feature.description!}
                    fillIcon={typeof feature.fillIcon === 'boolean' && feature.fillIcon}
                  />
                )
              })}
            </div>
          )}
        </div>
      </Gutter>
    </BlockSection>
  )
}
