import { InferComponentProps } from '@navinc/base-react-components'
import { FC } from 'react'

import { baseBlockComponents } from '../base-blocks.js'
import { LoaderBlocksData } from '../Blocks.jsx'

type BlockData = { __typename: keyof typeof baseBlockComponents } & InferComponentProps<
  (typeof baseBlockComponents)[keyof typeof baseBlockComponents]
>['data']

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
export const createExtendedBlocks = <T extends Record<string, FC<{ data: any }>>>(extraComponents: T) => {
  const allBlocks = {
    ...baseBlockComponents,
    ...extraComponents,
  }

  type AllBlockData = BlockData | { __typename: keyof typeof extraComponents }

  const BlockComponent = (props: { blocks: AllBlockData[] | null; loaderBlocksData?: LoaderBlocksData }) => {
    const { blocks, loaderBlocksData } = props

    if (!blocks || !Array.isArray(blocks) || blocks.length === 0) {
      return null
    }

    return (
      <>
        {blocks.map((block, index) => {
          const blockType = block.__typename as string

          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
          const Block = allBlocks[blockType] as any
          if (Block) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
            return <Block key={`${blockType}-${index}`} data={block as any} loaderBlocksData={loaderBlocksData} />
          }
          return null
        })}
      </>
    )
  }

  return BlockComponent
}

export const Blocks = createExtendedBlocks({})
