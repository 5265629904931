import { FragmentOf, graphql } from '../lib/cms.js'

export const cmsVideoFragment = graphql(/* GraphQL */ `
  fragment cmsVideoFragment on CmsVideo @_unmask {
    filename
    width
    height
    url
  }
`)

export type VideoFragmentType = FragmentOf<typeof cmsVideoFragment>
