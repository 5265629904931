import { Accordion, accordionFragment } from './Accordion/index.jsx'
import { ArchiveBlock, archiveFragment } from './ArchiveBlock/index.jsx'
import { BentoBlock, bentoFragment } from './Bento/index.jsx'
import { CallToActionBlock, callToActionBlockFragment } from './CallToAction/index.jsx'
import { CarouselBlock, carouselFragment } from './Carousel/index.jsx'
import { ContentBlock, contentFragment } from './Content/index.jsx'
import { CrossLinks, crossLinksFragment } from './CrossLinks/index.jsx'
import { DevicePreview, devicePreviewFragment } from './DevicePreview/index.jsx'
import { Disclaimers, disclaimersFragment } from './Disclaimers/index.jsx'
import { DownloadBanner, downloadBannerFragment } from './DownloadBanner/index.jsx'
import { FormBlock, formBlockFragment } from './DynamicForm/index.jsx'
import { FeatureGridBlock, featureGridFragment } from './FeatureGrid/index.jsx'
import { FeatureSection, featureSectionFragment } from './FeatureSection/index.jsx'
import { ImageBlock, imageBlockFragment } from './ImageBlock/index.jsx'
import { LegalBlock, legalBlockFragment } from './LegalBlock/index.jsx'
import { OfferBlock, offerBlockFragment } from './OfferBlock/index.jsx'
import { PriceTierBlock, priceTierFragment } from './PriceTier/index.jsx'
import { quoteFragment, Quotes } from './Quotes/index.jsx'
import { Resources, resourcesFragment } from './Resources/index.jsx'
import { RichTextSection, richTextSectionFragment } from './RichTextSection/index.jsx'
import { TableBlock, tableBlockFragment } from './TableBlock/index.jsx'
import { VideoBlock, videoBlockFragment } from './VideoBlock/index.jsx'

export const baseBlocksFragmentString = `
  ...callToActionBlockFragment
  ...devicePreviewFragment
  ...contentFragment
  ...archiveFragment
  ...quoteFragment
  ...crossLinksFragment
  ...accordionFragment
  ...featureSectionFragment
  ...carouselFragment
  ...bentoFragment
  ...priceTierFragment
  ...formBlockFragment
  ...resourcesFragment
  ...featureGridFragment
  ...disclaimersFragment
  ...legalBlockFragment
  ...videoBlockFragment
  ...richTextSectionFragment
  ...offerBlockFragment
  ...tableBlockFragment
  ...imageBlockFragment
  ...downloadBannerFragment
`

export const baseBlocksFragments = [
  callToActionBlockFragment,
  devicePreviewFragment,
  contentFragment,
  archiveFragment,
  quoteFragment,
  crossLinksFragment,
  accordionFragment,
  featureSectionFragment,
  carouselFragment,
  bentoFragment,
  priceTierFragment,
  formBlockFragment,
  resourcesFragment,
  featureGridFragment,
  disclaimersFragment,
  legalBlockFragment,
  videoBlockFragment,
  richTextSectionFragment,
  offerBlockFragment,
  tableBlockFragment,
  imageBlockFragment,
  downloadBannerFragment,
]

export const baseBlockComponents = {
  CmsCTA: CallToActionBlock,
  CmsDevicePreview: DevicePreview,
  CmsContent: ContentBlock,
  CmsArchive: ArchiveBlock,
  CmsQuote: Quotes,
  CmsCrossLinks: CrossLinks,
  CmsAccordion: Accordion,
  CmsFeatureSection: FeatureSection,
  CmsCarousel: CarouselBlock,
  CmsBento: BentoBlock,
  CmsPriceTier: PriceTierBlock,
  CmsFormBlock: FormBlock,
  CmsResources: Resources,
  CmsFeatureGrid: FeatureGridBlock,
  CmsDisclaimers: Disclaimers,
  CmsLegalBlock: LegalBlock,
  CmsVideoBlock: VideoBlock,
  CmsRichTextSection: RichTextSection,
  CmsOfferBlock: OfferBlock,
  CmsTableBlock: TableBlock,
  CmsImageBlock: ImageBlock,
  CmsDownloadBanner: DownloadBanner,
} as const
