type TableColumn = { header: string | null }
type TableRow = { cells: TableCell[] | null }
type TableCell = { cell: string | null }
export type TableProps = {
  columns: TableColumn[] | null | undefined
  rows?: TableRow[] | null | undefined
}

export const Table = ({ columns, rows }: TableProps) => (
  <table className=" mt-200 min-w-full rounded-200 border border-outlineVariant bg-surfaceBright px-200 pt-200 shadow-elevation1">
    <thead>
      <tr className="border-b border-outlineVariant bg-surfaceContainerLow">
        {columns?.map((column, thColumnIndex) => (
          <th key={thColumnIndex} className="p-200 text-left">
            {column.header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows?.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          className="border-b border-outlineVariant last:border-0 even:bg-surfaceBright odd:bg-surfaceDim"
        >
          {row?.cells?.map((cell, cellIndex) => {
            return (
              <td key={cellIndex} className="'body2 text-onSurfaceVariant'">
                <span className="flex flex-col p-200">{cell.cell}</span>
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  </table>
)
