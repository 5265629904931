import { cmsBlockSectionFragment, BlockSection } from '@/app/_components/BlockSection/index.jsx'
import { Table } from '@/app/_components/Table/Table.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { cmsSectionFragment, SectionHeader } from '../../_components/SectionHeader/index.jsx'

export const tableBlockFragment = graphql(
  /* GraphQL */ `
    fragment tableBlockFragment on CmsTableBlock {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      table {
        columns {
          header
        }
        rows {
          cells {
            cell
          }
        }
      }
    }
  `,
  [cmsLinkFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type TableBlockProps = {
  data: FragmentOf<typeof tableBlockFragment>
  isNested?: boolean
}

export const TableBlock = ({ data, isNested }: TableBlockProps) => {
  const { section, table, ctas, invertBackground } = readFragment(tableBlockFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested} section={section}>
      <SectionHeader data={section} links={ctas} />
      <div className="w-full overflow-x-scroll">
        <Table columns={table?.columns} rows={table?.rows} />
      </div>
    </BlockSection>
  )
}
