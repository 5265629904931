import { cn } from '@navinc/base-react-components'

export const HeroDisclaimer = ({
  children,
  horizontalAlign,
}: {
  children: string
  horizontalAlign?: 'left' | 'right' | 'center' | null
}) => {
  const disclaimerAlignmentClass = {
    left: 'justify-self-start',
    right: 'justify-self-end',
    center: 'justify-self-center',
  }[horizontalAlign ?? 'left']
  return <p className={cn('caption2 pt-400 text-onSurfaceDim', disclaimerAlignmentClass)}>{children}</p>
}
