import { ReactNode, useRef, useState } from 'react'
import { useIsOverflowed } from './use-is-overflowed.js'
import { Button } from '@navinc/base-react-components/wayfinder'
import { cn } from '@navinc/base-react-components'

export const OverflowText = ({ children }: { children: ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const copyRef = useRef<null | HTMLDivElement>(null)
  const isOverflowed = useIsOverflowed(copyRef)
  return (
    <div
      className={cn(
        'relative flex overflow-hidden pb-[50px] max-h-[250px] md:max-h-[150px]',
        isExpanded && 'pb-[50px] max-h-[inherit] md:max-h-[inherit]',
      )}
    >
      <div ref={copyRef}>{children}</div>
      {((!isExpanded && isOverflowed) || isExpanded) && (
        <div>
          <div className="absolute bottom-0 left-0 right-0 pointer-events-none pb-250">
            {!isExpanded && (
              <div
                className="h-800 w-full"
                style={{
                  background:
                    'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 60%, rgba(255,255,255,1) 100%)',
                }}
              />
            )}
          </div>
          <Button
            variant="primaryPlain"
            onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
            className="absolute bottom-0 left-0 pointer-events-auto w-full bg-surface -ml-50 pl-50 text-left justify-start"
          >
            {isExpanded ? 'See Less' : 'See More'}
          </Button>
        </div>
      )}
    </div>
  )
}
