import { FragmentOf, readFragment } from 'gql.tada'

import { BlockSection } from '@/app/_components/BlockSection/index.jsx'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment, CMSLinkFromFragment } from '../../_components/Link/index.js'
import { RichText } from '../../_components/RichText/index.js'
import { Children } from '../../_components/RichText/serialize.js'
import { graphql } from '../../lib/cms.js'
import { cn } from '@navinc/base-react-components'

export const contentFragment = graphql(
  /* GraphQL */ `
    fragment contentFragment on CmsContent {
      invertBackground
      columnsAlign
      columnsMaxWidth
      columns {
        richText
        colSpanLg
        colSpanMd
        colSpanSm
        ctas {
          link {
            ...cmsLinkFragment
          }
        }
        ctaAlign
      }
    }
  `,
  [cmsLinkFragment],
)

export type ContentBlockProps = {
  data: FragmentOf<typeof contentFragment>
  isNested?: boolean
}

const DEFAULT_COL_SPAN = 12

const getColSpanLarge = (colSpan: number | null) => {
  if (colSpan === null) {
    return DEFAULT_COL_SPAN
  }
  const colSpanKey = `${colSpan}`
  return (
    {
      '1': 'lg:col-span-1',
      '2': 'lg:col-span-2',
      '3': 'lg:col-span-3',
      '4': 'lg:col-span-4',
      '5': 'lg:col-span-5',
      '6': 'lg:col-span-6',
      '7': 'lg:col-span-7',
      '8': 'lg:col-span-8',
      '9': 'lg:col-span-9',
      '10': 'lg:col-span-10',
      '11': 'lg:col-span-11',
      '12': 'lg:col-span-12',
    }[colSpanKey] ?? DEFAULT_COL_SPAN
  )
}
const getColSpanMedium = (colSpan: number | null) => {
  if (colSpan === null) {
    return DEFAULT_COL_SPAN
  }
  const colSpanKey = `${colSpan}`
  return (
    {
      '1': 'md:col-span-1',
      '2': 'md:col-span-2',
      '3': 'md:col-span-3',
      '4': 'md:col-span-4',
      '5': 'md:col-span-5',
      '6': 'md:col-span-6',
      '7': 'md:col-span-7',
      '8': 'md:col-span-8',
      '9': 'md:col-span-9',
      '10': 'md:col-span-10',
      '11': 'md:col-span-11',
      '12': 'md:col-span-12',
    }[colSpanKey] ?? DEFAULT_COL_SPAN
  )
}
const getColSpanSmall = (colSpan: number | null) => {
  if (colSpan === null) {
    return DEFAULT_COL_SPAN
  }
  const colSpanKey = `${colSpan}`
  return (
    {
      '1': 'col-span-1',
      '2': 'col-span-2',
      '3': 'col-span-3',
      '4': 'col-span-4',
      '5': 'col-span-5',
      '6': 'col-span-6',
      '7': 'col-span-7',
      '8': 'col-span-8',
      '9': 'col-span-9',
      '10': 'col-span-10',
      '11': 'col-span-11',
      '12': 'col-span-12',
    }[colSpanKey] ?? DEFAULT_COL_SPAN
  )
}

export const ContentBlock = ({ data, isNested }: ContentBlockProps) => {
  const { columns, invertBackground, columnsAlign, columnsMaxWidth } = readFragment(contentFragment, data)

  const columnAlignClass = { center: 'mx-auto justify-center', left: 'justify-start', right: 'justify-end' }[
    columnsAlign ?? 'left'
  ]
  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested}>
      <Gutter>
        <div className={cn('flex w-full', columnAlignClass)}>
          <div
            className={cn('grid grid-cols-12 gap-300')}
            style={{ maxWidth: columnsMaxWidth ? `${columnsMaxWidth}px` : 'none' }}
          >
            {columns &&
              columns.length > 0 &&
              columns.map((col, index) => {
                const { richText, ctas, ctaAlign, colSpanLg, colSpanMd, colSpanSm } = col
                const alignmentClasses = {
                  center: 'justify-center',
                  left: 'justify-start',
                  right: 'justify-end',
                }[ctaAlign ?? 'left']

                return (
                  <div
                    key={index}
                    className={cn(getColSpanLarge(colSpanLg), getColSpanMedium(colSpanMd), getColSpanSmall(colSpanSm))}
                  >
                    <RichText content={richText as Children} />

                    {ctas && ctas.length ? (
                      <div className={`CTA flex ${alignmentClasses} my-200`}>
                        <ul className="m-0 flex list-none gap-200 p-0 w-full md:w-auto">
                          {ctas.map(({ link }, i) => {
                            if (!link) return null

                            return (
                              <li key={i} className="w-[inherit]">
                                <CMSLinkFromFragment data={link} density="standard" className="w-[inherit]" />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                )
              })}
          </div>
        </div>
      </Gutter>
    </BlockSection>
  )
}
