import { Button } from '@navinc/base-react-components/wayfinder'
import { OfferComparisonTable } from './OfferComparisonTable.jsx'
import { MarketplaceCreditCard } from './queries/credit-card-offers-query.js'
import { OfferApplyCtaSection } from './shared/OfferApplyCtaSection.jsx'
import { OfferInfoBulletList } from './shared/OfferBulletListItems.jsx'
import { OfferCtaButton } from './shared/OfferCtaButton.jsx'
import { OfferDrawer } from './shared/OfferDrawer.jsx'
import { OfferProsCons } from './shared/OfferProsCons.jsx'
import { OffersListLayout } from './shared/OffersListLayout.jsx'
import { OfferSpecs } from './shared/OfferSpecs.jsx'
import { OfferTabs } from './shared/OfferTabs.jsx'
import { OfferFilter } from './shared/use-offer-filters.js'

export const CreditCardOffer = ({ offer }: { offer: MarketplaceCreditCard }) => {
  if (!offer) {
    return null
  }

  return (
    <div className=" rounded-200 border border-outlineVariant px-200 pt-200 shadow-elevation1">
      <div className="row1 grid grid-cols-12 gap-200">
        <div className="col-span-4 md:col-span-2">
          <div className="flex flex-col items-center gap-200 py-200">
            <img src={offer.image.standard} alt={`${offer.name} Logo`} />
            <OfferCtaButton url={offer.applyUrl}>{offer.applyButtonText}</OfferCtaButton>
          </div>
        </div>
        <div className="col-span-8 md:col-span-10">
          <div className="p-200">
            <div className="mb-200">
              <p className="title3-emphasized">{offer.name}</p>
              <p className="body2">
                {offer.terms.partnerText && <span>{offer.terms.partnerText} | </span>}
                {offer.terms.link && offer.terms.linkText && (
                  <Button variant="primaryPlain" asChild>
                    <a href={offer.terms.link} target="_blank" rel="noopener noreferrer">
                      {offer.terms.linkText}
                    </a>
                  </Button>
                )}
                {offer.issuer === 'American Express' && `${offer.terms.link ? ' | ' : ''}Terms apply`}
              </p>
            </div>
            <p className="caption1 mb-100 rounded-100 bg-[#B8B4F3] p-100">{offer.navEditorial.summary}</p>
            <OfferProsCons pros={offer.navEditorial.pros} cons={offer.navEditorial.cons} />
          </div>
        </div>
      </div>

      <OfferSpecs
        specs={[offer.aprs.introPurchaseApr, offer.aprs.purchaseApr, offer.fees.annualFee, offer.rewards.welcomeOffer]}
      />

      <OfferDrawer>
        <OfferTabs
          tabs={[
            {
              value: 'ratesFees',
              title: 'Rates & Fees',
              content: (
                <div className="flex flex-col gap-200">
                  {[
                    offer.aprs.introPurchaseApr,
                    offer.aprs.purchaseApr,
                    offer.fees.annualFee,
                    offer.fees.foreignTransactionFee,
                    offer.rewards.rewardsRate,
                    offer.rewards.welcomeOffer,
                  ].map((item, index) => (
                    <div key={index} className="grid grid-cols-12 gap-300">
                      <p className="body2-emphasized col-span-4 md:col-span-3">{item.title}</p>
                      <p className="body2 col-span-8 md:col-span-9">{item.description}</p>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              value: 'details',
              title: 'Details',
              content: <OfferInfoBulletList data={offer.marketingCopy} />,
            },
            {
              value: 'recommendation',
              title: 'Our Recommendation',
              content: (
                <div className="py-200">
                  <p>{offer.navEditorial.summary}</p>
                </div>
              ),
            },
            {
              value: 'terms',
              title: 'Terms & Conditions',
              content: <p>{offer.navEditorial.termsAndConditions}</p>,
              hide: !offer.navEditorial.termsAndConditions,
            },
          ]}
        />
        <OfferApplyCtaSection url={offer.applyUrl} buttonText={offer.applyButtonText} />
      </OfferDrawer>
    </div>
  )
}

export const CreditCardOffers = ({ offers, filters }: { offers: MarketplaceCreditCard[]; filters: OfferFilter[] }) => {
  return (
    <>
      <OffersListLayout
        offers={offers}
        filters={filters}
        renderOffer={({ offer }) => <CreditCardOffer offer={offer as MarketplaceCreditCard} />}
      />
      <OfferComparisonTable
        offers={offers.map((offer) => {
          return {
            title: (
              <div className="flex flex-col md:flex-row gap-100">
                <div className="h-auto max-w-[75px]">
                  <img src={offer.image.standard} alt={`${offer.name} Logo`} />
                </div>{' '}
                <strong className="font-semibold">{offer.name}</strong>
              </div>
            ),
            purchaseApr: offer.aprs.purchaseApr.description,
            annualFee: offer.fees.annualFee.description,
          }
        })}
        columns={[
          {
            key: 'title',
            label: 'Compare your options',
          },
          {
            key: 'purchaseApr',
            label: 'Purchase APR',
          },
          {
            key: 'annualFee',
            label: 'Annual Fee',
          },
        ]}
      />
    </>
  )
}
