import { cmsBlockSectionFragment, BlockSection } from '../../_components/BlockSection/index.jsx'
import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { cmsSectionFragment, SectionHeader } from '../../_components/SectionHeader/index.jsx'
import { Video } from '../../_components/Video/index.jsx'
import { cmsVideoFragment } from '../../_graphql/videos.js'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const videoBlockFragment = graphql(
  /* GraphQL */ `
    fragment videoBlockFragment on CmsVideoBlock {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      video {
        ...cmsVideoFragment
      }
    }
  `,
  [cmsLinkFragment, cmsVideoFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type VideoBlockProps = {
  data: FragmentOf<typeof videoBlockFragment>
  isNested?: boolean
}

export const VideoBlock = ({ data, isNested }: VideoBlockProps) => {
  const { section, video, ctas, invertBackground } = readFragment(videoBlockFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} isNested={isNested} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        {video?.url && (
          <div className="relative z-10">
            <Video src={video.url} />
          </div>
        )}
      </Gutter>
    </BlockSection>
  )
}
