import { Button } from '@navinc/base-react-components/wayfinder'

import { ActiveFilters } from './use-offer-filters.js'

type OfferResultsProps = {
  offersListCount: number
  visibleCount: number
  activeFilters?: ActiveFilters
  handleClearFilters?: () => void
}
export const OfferResults = ({
  offersListCount,
  visibleCount,
  activeFilters,
  handleClearFilters,
}: OfferResultsProps) => {
  return (
    <div>
      <p className="body2-emphasized">
        Showing 1 - {visibleCount} of {offersListCount} results
      </p>

      {handleClearFilters && activeFilters && Object.keys(activeFilters).length && offersListCount === 0 ? (
        <div className="body1-emphasized flex flex-col items-center justify-center gap-300 rounded-200 border border-outlineVariant px-200 py-500 shadow-elevation1">
          There are no results matching the selected filters.{' '}
          <Button onClick={handleClearFilters}>Clear all filters</Button>
        </div>
      ) : null}
    </div>
  )
}
