import { collections } from '@cms/collections/collections.js'
import type { PayloadTypes } from '@cms/index.js'
import qs from 'qs'
import { useEffect, useRef, useState } from 'react'

import { clientEnv } from '@/app/lib/env.js'

import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { Gutter } from '../Gutter/index.js'
import { PageRange } from '../PageRange/index.js'
import { Pagination } from '../Pagination/index.js'
import { ResourceCard } from '../ResourceCard/index.jsx'
import { SmartLink } from '../SmartLink/SmartLink.jsx'
import placeholderImg from './placeholder.jpg'

const articleFragment = graphql(/* GraphQL */ `
  fragment articleFragment on CmsArticle {
    id
    slug
    title
    meta {
      image {
        url
      }
    }
  }
`)

export const collectionArchiveSelectedDocs = graphql(
  /* GraphQL */ `
    fragment collectionArchiveSelectedDocs on CmsArchive_SelectedDocs_Relationship @_unmask {
      relationTo
      value {
        ...articleFragment
      }
    }
  `,
  [articleFragment],
)

export const collectionArchivePopulatedDocs = graphql(
  /* GraphQL */ `
    fragment collectionArchivePopulatedDocs on CmsArchive_PopulatedDocs_Relationship @_unmask {
      relationTo
      value {
        ...articleFragment
      }
    }
  `,
  [articleFragment],
)

export const collectionArchiveCategoriesFragment = graphql(/* GraphQL */ `
  fragment collectionArchiveCategoriesFragment on CmsCategories {
    id
  }
`)

type Result = {
  docs: (FragmentOf<typeof articleFragment> | null)[] | undefined
  hasNextPage: boolean
  hasPrevPage: boolean
  nextPage: number
  page: number
  prevPage: number
  totalDocs: number
  totalPages: number
}

export type Props = {
  categories?: FragmentOf<typeof collectionArchiveCategoriesFragment>[] | null
  limit?: number | null
  onResultChange?: (result: Result) => void
  populateBy?: 'collection' | 'selection' | null
  populatedDocs?: FragmentOf<typeof collectionArchivePopulatedDocs>[] | null
  populatedDocsTotal?: number | null
  relationTo?: typeof collections.articles | null
  selectedDocs: FragmentOf<typeof collectionArchiveSelectedDocs>[] | null
  showPageRange?: boolean
  sort?: string
}

export const CollectionArchive = ({
  // appearance,
  categories: catsFromProps,
  limit = 10,
  onResultChange,
  populateBy,
  populatedDocs,
  populatedDocsTotal,
  relationTo,
  selectedDocs,
  showPageRange,
  sort = '-createdAt',
}: Props) => {
  const [results, setResults] = useState<Result>({
    docs: (populateBy === 'collection' ? populatedDocs : populateBy === 'selection' ? selectedDocs : [])?.map(
      (doc) => doc.value,
    ),
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 1,
    page: 1,
    prevPage: 1,
    totalDocs: typeof populatedDocsTotal === 'number' ? populatedDocsTotal : 0,
    totalPages: 1,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const hasHydrated = useRef(false)
  const isRequesting = useRef(false)
  const [page, setPage] = useState(1)

  const categories = (catsFromProps || [])
    .map((cat) => readFragment(collectionArchiveCategoriesFragment, cat).id)
    .join(',')

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (populateBy === 'collection' && !isRequesting.current) {
      isRequesting.current = true

      // hydrate the block with fresh content after first render
      // don't show loader unless the request takes longer than x ms
      // and don't show it during initial hydration
      timer = setTimeout(() => {
        if (hasHydrated.current) {
          setIsLoading(true)
        }
      }, 500)

      const searchQuery = qs.stringify(
        {
          depth: 1,
          limit,
          page,
          sort,
          where: {
            ...(categories
              ? {
                  categories: {
                    in: categories,
                  },
                }
              : {}),
          },
        },
        { encode: false },
      )

      const makeRequest = async (): Promise<void> => {
        try {
          const req = await fetch(`${clientEnv.NAV_CMS_URL}/api/${relationTo}?${searchQuery}`)

          const json = await req.json()
          if (timer) {
            clearTimeout(timer)
          }

          const { docs } = json as {
            docs: PayloadTypes.Article[]
          }

          if (docs && Array.isArray(docs)) {
            setResults(json)
            setIsLoading(false)
            if (typeof onResultChange === 'function') {
              onResultChange(json)
            }
          }
        } catch (err) {
          console.error(err) // eslint-disable-line no-console -- temporary until error handling + bugsnag are added
          setIsLoading(false)
          setError(`Unable to load "${relationTo} archive" data at this time.`)
        }

        isRequesting.current = false
        hasHydrated.current = true
      }

      void makeRequest() // eslint-disable-line no-void -- TODO: temporary until i figure out what this does
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [page, categories, relationTo, onResultChange, sort, limit, populateBy])

  return (
    <div>
      {!isLoading && error && <Gutter>{error}</Gutter>}
      <>
        {showPageRange !== false && populateBy !== 'selection' && (
          <Gutter>
            <div>
              <PageRange
                collection={relationTo}
                currentPage={results.page}
                limit={limit}
                totalDocs={results.totalDocs}
              />
            </div>
          </Gutter>
        )}
        <Gutter>
          <ul className="m-0 flex flex-col justify-center gap-300 p-0 lg:flex-row">
            {results.docs?.map((result) => {
              if (!result) {
                return null
              }

              const { id, title, meta, slug } = readFragment(articleFragment, result)

              const mediaUrl = meta?.image?.url ?? placeholderImg

              const href = `/${relationTo}/${slug}`
              return (
                <li key={id}>
                  <SmartLink to={href} title={title ?? undefined}>
                    <ResourceCard title={title ?? 'TODO'} mediaUrl={mediaUrl} />
                  </SmartLink>
                </li>
              )
            })}
          </ul>
          {results.totalPages > 1 && populateBy !== 'selection' && (
            <Pagination onClick={setPage} page={results.page} totalPages={results.totalPages} />
          )}
        </Gutter>
      </>
    </div>
  )
}
