import { useSearchParams } from '@remix-run/react'
import { useCallback } from 'react'

import { MarketplaceCreditCard } from '../queries/credit-card-offers-query.js'
import { MarketplaceLoanOffer } from '../queries/loan-offers-query.js'
import { MarketplaceProductOption } from '../queries/product-category-offers.query.js'

export type OfferFilter =
  | ({
      name: string | null
      options: (string | null)[] | null
    } | null)
  | null

export type ActiveFilters = { [key: string]: string[] }

export const createTagFilter = ({ activeFilters = {} }: { activeFilters: ActiveFilters; tagsKey?: string }) => {
  return (offer: MarketplaceProductOption | MarketplaceLoanOffer | MarketplaceCreditCard) => {
    const entries = Object.entries(activeFilters)

    if (!entries.length) {
      return true
    }

    return entries.some(([activeName = '', activeValues = []]) => {
      return offer.tags.some(({ name, value }) => {
        if (!value) {
          return false
        }
        return name === activeName && activeValues.includes(value)
      })
    })
  }
}

export const useOfferFilters = ({ filters }: { filters: OfferFilter[] }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getActiveFilters = () => {
    const activeFilters: ActiveFilters = {}

    filters.forEach((filter) => {
      if (!filter || !filter.name) {
        return
      }
      const filterName = `filter.${filter.name}`
      const selectedOptions = searchParams.getAll(filterName)

      if (selectedOptions.length > 0) {
        activeFilters[filter.name] = selectedOptions
      }
    })

    return activeFilters
  }

  const handleClearFilters = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams)

    // Loop over each filter and remove its parameters
    filters.forEach((filter) => {
      if (!filter) {
        return
      }
      const filterName = `filter.${filter.name}`
      newSearchParams.delete(filterName)
    })

    setSearchParams(newSearchParams, { replace: true })
  }, [filters, searchParams, setSearchParams])

  const handleFilterChange = (filterName: string, option: string, checked: boolean | string) => {
    const newSearchParams = new URLSearchParams(searchParams)
    const filterQuery = searchParams.getAll(filterName)
    const selectedOptions = new Set(filterQuery)

    if (checked) {
      // Add the selected option to the set
      selectedOptions.add(option)
    } else {
      // Remove the unselected option from the set
      selectedOptions.delete(option)
    }

    // Update the search params by removing the old key and adding the new list of options
    newSearchParams.delete(filterName)
    selectedOptions.forEach((opt) => newSearchParams.append(filterName, opt))

    setSearchParams(newSearchParams, { replace: true, preventScrollReset: true })
  }

  return {
    activeFilters: getActiveFilters(),
    handleFilterChange,
    handleClearFilters,
    createTagFilter,
    getFilterValueByName: (filterName: string) => searchParams.getAll(filterName),
  }
}
