import { cn } from '@navinc/base-react-components'

import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'
import { ResourceCard } from '@/app/_components/ResourceCard/index.jsx'
import { cmsSectionFragment, SectionHeader } from '@/app/_components/SectionHeader/index.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'
import { resolveHref, resolveHrefFragment } from '@/app/lib/resolveHref.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment, cmsLinkWithMetaFragment } from '../../_components/Link/index.jsx'
import { SmartLink } from '../../_components/SmartLink/SmartLink.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const resourcesFragment = graphql(
  /* GraphQL */ `
    fragment resourcesFragment on CmsResources {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      cardsGridType
      cards {
        media {
          ...CmsMediaFragment
        }
        title
        description
        linkAppearance
        link {
          ...cmsLinkWithMetaFragment
          ...resolveHrefFragment
        }
      }
    }
  `,
  [
    cmsLinkFragment,
    CmsMediaFragment,
    cmsLinkWithMetaFragment,
    resolveHrefFragment,
    cmsSectionFragment,
    cmsBlockSectionFragment,
  ],
)

export type ResourcesProps = {
  data: FragmentOf<typeof resourcesFragment>
}

const ResourceCardComponent = ({
  title,
  description,
  mediaUrl,
  linkAppearance,
  link,
}: {
  title: string
  description: string
  mediaUrl?: string
  linkAppearance?: 'cta' | 'full_card' | null
  link?: (FragmentOf<typeof cmsLinkWithMetaFragment> & FragmentOf<typeof resolveHrefFragment>) | null
}) => {
  const resourceCardProps = {
    description,
    title,
    mediaUrl,
  }

  const href = link ? resolveHref(link) : undefined
  if (href) {
    if (linkAppearance === 'cta') {
      return <ResourceCard {...resourceCardProps} link={link} />
    }
    return (
      <SmartLink to={href} title={title}>
        <ResourceCard {...resourceCardProps} />
      </SmartLink>
    )
  }

  return <ResourceCard {...resourceCardProps} />
}

export const Resources = ({ data }: ResourcesProps) => {
  const { cards, section, ctas, invertBackground, cardsGridType } = readFragment(resourcesFragment, data)

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <SectionHeader data={section} links={ctas} />
        <ul
          className={`m-0 flex flex-wrap justify-center gap-500 p-0  ${cardsGridType === 'condensed' ? 'gap-200' : 'gap-500'}`}
        >
          {cards?.map((card, index) => {
            const title = card.title ?? ''
            const description = card.description ?? ''
            const mediaUrl = getMediaSizeUrlWithFallback(card.media, 'card')

            const gridStyleClass = {
              standard: 'w-full sm:w-[40%] md:w-[20%] sm:max-w-[320px]',
              condensed: 'max-w-[200px]',
            }[cardsGridType ?? 'standard']

            return (
              <li key={`${title}-${index}`} className={cn(gridStyleClass, 'w-full')}>
                <ResourceCardComponent
                  link={card.link}
                  title={title}
                  description={description}
                  mediaUrl={mediaUrl}
                  linkAppearance={card.linkAppearance}
                />
              </li>
            )
          })}
        </ul>
      </Gutter>
    </BlockSection>
  )
}
