import { useState } from 'react'

export const useShowMoreLess = <ItemType>(items: ItemType[], initialItemsPerPage: number) => {
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage)

  // Get the items to display based on the current itemsPerPage
  const currentItems = items.slice(0, itemsPerPage)

  const showMoreItems = () => {
    setItemsPerPage((prev) => Math.min(prev + initialItemsPerPage, items.length))
  }

  const showLessItems = () => {
    setItemsPerPage(initialItemsPerPage)
  }

  const hasMoreItems = itemsPerPage < items.length
  const hasLessItems = itemsPerPage > initialItemsPerPage

  return {
    currentItems,
    showMoreItems,
    showLessItems,
    hasMoreItems,
    hasLessItems,
  }
}
