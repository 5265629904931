import { BlockSection, cmsBlockSectionFragment } from '@/app/_components/BlockSection/index.jsx'
import { getMediaSizeUrlWithFallback } from '@/app/_components/Media/utils.js'
import { Children } from '@/app/_components/RichText/serialize.jsx'
import { CmsMediaFragment } from '@/app/_graphql/media.js'

import { Gutter } from '../../_components/Gutter/index.js'
import { cmsLinkFragment } from '../../_components/Link/index.jsx'
import { RichText } from '../../_components/RichText/index.jsx'
import { cmsSectionFragment, SectionHeader } from '../../_components/SectionHeader/index.jsx'
import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'

export const featureSectionFragment = graphql(
  /* GraphQL */ `
    fragment featureSectionFragment on CmsFeatureSection {
      invertBackground
      section {
        ...cmsSectionFragment
        ...cmsBlockSectionFragment
      }
      ctas {
        link {
          ...cmsLinkFragment
        }
      }
      align
      template
      tmplStats {
        data
        description
      }
      bgMedia {
        ...CmsMediaFragment
      }
    }
  `,
  [cmsLinkFragment, CmsMediaFragment, cmsSectionFragment, cmsBlockSectionFragment],
)

export type FeatureSectionProps = {
  data: FragmentOf<typeof featureSectionFragment>
}

export const FeatureSection = ({ data }: FeatureSectionProps) => {
  const { align, section, ctas, template, tmplStats, bgMedia, invertBackground } = readFragment(
    featureSectionFragment,
    data,
  )

  const textContent = (
    <div className="relative z-30 col-span-12 flex items-center md:col-span-6 md:justify-center md:px-400">
      <div className="flex w-full flex-col items-center">
        <SectionHeader
          data={section}
          links={ctas}
          textAlignClassName="md:text-left text-center items-center md:items-start md:mx-0"
        />

        {template === 'stats' && tmplStats?.length ? (
          <ul className="flex flex-col gap-500 md:grid md:grid-cols-12 md:items-center">
            {tmplStats.map((stat, key) => {
              return (
                <li key={key} className="col-span-12 max-w-[400px] text-center md:col-span-6 md:text-left">
                  <p className="display1-emphasized text-primary">{stat.data}</p>
                  <RichText content={stat.description as Children} />
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
    </div>
  )

  const imageContent = (
    <>
      <div
        className="Feature relative col-span-12 overflow-hidden rounded-200 bg-cover bg-center pb-[56%] md:col-span-6"
        style={{ backgroundImage: `url("${getMediaSizeUrlWithFallback(bgMedia, 'feature')}")` }}
        role="img"
        aria-label={bgMedia?.alt}
      />
    </>
  )

  return (
    <BlockSection invertBackground={invertBackground} section={section}>
      <Gutter>
        <div
          className={`flex flex-col gap-300 md:grid md:grid-cols-12 ${align === 'imageAlignRight' ? 'flex-col-reverse' : ''}`}
        >
          {align === 'imageAlignLeft' ? imageContent : textContent}
          {align === 'imageAlignLeft' ? textContent : imageContent}
        </div>
      </Gutter>
    </BlockSection>
  )
}
